import { FontAwesome, SimpleLineIcons, Zocial } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation, useRoute } from "@react-navigation/core";
import PropTypes from "prop-types";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  Image,
  Keyboard,
  KeyboardAvoidingView,
  LayoutAnimation,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Input, Tooltip } from "react-native-elements";
import SafeAreaView from "react-native-safe-area-view";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchForgetPassword,
  fetchLogin,
  fetchLoginAsGuest,
  fetchLoginUsingToken,
  fetchSignUp,
  fetchSignupAsGuest
} from "../actions/login";
import {
  CLEAR_FORGET_PWD_VALIDATE,
  CLEAR_LOGIN,
  CLEAR_VALIDATE,
  CLEAR_VALIDATE_GUEST,
  LOGOUT,
  SET_EMAIL_OR_PSEUDO,
  SET_PASSWORD,
  SET_REGISTER_KEY,
  SET_SELECTED_CATEGORY,
  SET_TOOLTIP3_VISIBLE,
  SET_TOOLTIP4_VISIBLE,
  SET_TOOLTIP5_VISIBLE,
  SET_TOOLTIP6_VISIBLE,
  VALIDATE_APPLE_INPUTS,
  VALIDATE_FORGET_PWD_INPUT,
  VALIDATE_GUEST_INPUT,
  VALIDATE_INPUTS
} from "../actions/types";
import { getImage } from "../assets/Images";
import { BUSINESS_NAME } from "../config/constants";
import { getLoginSelector } from "../selectors";
import { t } from "../services/i18n";
import commonStyles, {
  DARKGREY_COLOR,
  isAndroid,
  isElectron,
  isiOS,
  SCREEN_WIDTH,
  WHITE_COLOR
} from "../styles/commonStyles";
import FBLogin from "./FacebookLogin";

let majorVersionIOS = 0;
if (isiOS) {
  majorVersionIOS = parseInt(Platform.Version, 10);
}

const TabSelector = ({ selected }) => {
  const { flex1, alignItemsCenter } = commonStyles;
  return (
    <View style={[flex1, alignItemsCenter]}>
      <View style={selected && styles.selected} />
    </View>
  );
};

TabSelector.propTypes = {
  selected: PropTypes.bool.isRequired
};

const Login = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();

  const tooltip3Ref = useRef();
  const tooltip4Ref = useRef();
  const tooltip6Ref = useRef();
  const emailOrPseudoInputRef = useRef();
  const passwordInputRef = useRef();
  const registerkeyInputRef = useRef();

  const { token, communityUri, registerkey: registerkeyParam } = route.params;

  const {
    isFetching,
    emailOrPseudo,
    emailOrPseudoErrorMsg,
    password,
    passwordErrorMsg,
    valid,
    clearValidate,
    registerkey,
    registerkeyErrorMsg,
    clearValidateApple,
    validApple,
    clearForgetPwdValidate,
    validForgetPwd,
    selectedCategory,
    tokenErrorMsg,
    isTooltip3Visible,
    isTooltip4Visible,
    isTooltip6Visible,
    clearValidateSignupGuest,
    validSignupGuest,
    fromLogin,
    lang,
    installationId,
    dropDownAlertInfo,
    dropDownAlertError,
    currentCommunity
  } = useSelector(getLoginSelector, shallowEqual);

  const [showImage, setShowImage] = useState(true);

  const {
    _id: communityId,
    logoUri,
    name: communityName,
    needRegisterKey
  } = currentCommunity;
  const displayRegisterkey = needRegisterKey && !registerkeyParam;

  useEffect(() => {
    (async () => {
      const emailOrPseudo = await AsyncStorage.getItem("emailOrPseudo");
      if (emailOrPseudo) {
        dispatch({
          type: SET_EMAIL_OR_PSEUDO,
          value: emailOrPseudo
        });
      }
      const strategy = await AsyncStorage.getItem("strategy");
      if (!strategy && !token) {
        dispatchSelectCategory(1);
      }
    })();

    const keyboardWillShowSub = Keyboard.addListener(
      isiOS ? "keyboardWillShow" : "keyboardDidShow",
      () => {
        LayoutAnimation.easeInEaseOut();
        setShowImage(false);
      }
    );
    const keyboardWillHideSub = Keyboard.addListener(
      isiOS ? "keyboardWillHide" : "keyboardDidHide",
      () => {
        LayoutAnimation.easeInEaseOut();
        setShowImage(true);
      }
    );
    return () => {
      keyboardWillShowSub.remove();
      keyboardWillHideSub.remove();
      dispatch({
        type: CLEAR_LOGIN
      });
    };
  }, []);

  useEffect(() => {
    if (registerkeyParam) {
      dispatch({
        type: SET_REGISTER_KEY,
        value: registerkeyParam
      });
    }
  }, [registerkeyParam]);

  useEffect(() => {
    if (selectedCategory) {
      showTooltips();
    }
  }, [selectedCategory]);

  useEffect(() => {
    const infos = "popup1|popup2";
    if (dropDownAlertInfo && infos.indexOf(dropDownAlertInfo)) {
      dispatch({
        type: SET_SELECTED_CATEGORY,
        value: 0
      });
    }
  }, [dropDownAlertInfo]);

  useEffect(() => {
    const errors = "unknown|unauthorized";

    if (dropDownAlertError && errors.indexOf(!dropDownAlertError)) {
      dispatch({
        type: SET_SELECTED_CATEGORY,
        value: 1
      });
    }
  }, [dropDownAlertError]);

  const getCommunityUri = () => {
    return currentCommunity
      ? currentCommunity.customUri
        ? currentCommunity.customUri
        : currentCommunity._id
      : "";
  };

  useEffect(() => {
    if (token) {
      dispatch({
        type: SET_SELECTED_CATEGORY,
        value: 0
      });
      if (communityUri === getCommunityUri()) {
        dispatch(fetchLoginUsingToken(token));
      }
    }
  }, [token]);

  useEffect(() => {
    // Validate email inputs
    if (clearValidate) {
      dispatch({
        type: VALIDATE_INPUTS
      });
    }
  }, [clearValidate]);

  useEffect(() => {
    // Validate Apple inputs
    if (clearValidateApple) {
      dispatch({
        type: VALIDATE_APPLE_INPUTS
      });
    }
  }, [clearValidateApple]);

  useEffect(() => {
    // Validate email inputs
    if (clearForgetPwdValidate) {
      dispatch({
        type: VALIDATE_FORGET_PWD_INPUT
      });
    }
  }, [clearForgetPwdValidate]);

  useEffect(() => {
    if (clearValidateSignupGuest) {
      dispatch({
        type: VALIDATE_GUEST_INPUT
      });
    }
  }, [clearValidateSignupGuest]);

  useEffect(() => {
    if (passwordErrorMsg) {
      setTimeout(() => {
        passwordInputRef?.current?.shake();
        passwordInputRef?.current?.focus();
      }, 10);
    }
  }, [passwordErrorMsg]);

  useEffect(() => {
    if (registerkeyErrorMsg) {
      registerkeyInputRef?.current?.shake();
      registerkeyInputRef?.current?.focus();
    }
  }, [registerkeyErrorMsg]);

  useEffect(() => {
    if (emailOrPseudoErrorMsg) {
      emailOrPseudoInputRef?.current?.shake();
      emailOrPseudoInputRef?.current?.focus();
    }
  }, [emailOrPseudoErrorMsg]);

  useEffect(() => {
    if (valid) {
      if (selectedCategory === 1) {
        signUp();
      } else {
        login();
      }
    }
  }, [valid]);

  useEffect(() => {
    if (validApple) {
      logInWithApple();
    }
  }, [validApple]);

  useEffect(() => {
    if (validForgetPwd) {
      dispatch(fetchForgetPassword(communityId, emailOrPseudo, lang));
    }
  }, [validForgetPwd]);

  useEffect(() => {
    if (validSignupGuest) {
      signupAsGuest();
    }
  }, [validSignupGuest]);

  useEffect(() => {
    if (!isFetching && fromLogin && dropDownAlertError === "unexpectederror") {
      dispatch({
        type: LOGOUT
      });
    }
  }, [isFetching, fromLogin, dropDownAlertError]);

  useEffect(() => {
    if (isTooltip3Visible) {
      tooltip3Ref?.current?.toggleTooltip();
    }
  }, [isTooltip3Visible]);

  useEffect(() => {
    if (isTooltip4Visible) {
      tooltip4Ref?.current?.toggleTooltip();
    }
  }, [isTooltip4Visible]);

  useEffect(() => {
    if (isTooltip6Visible) {
      tooltip6Ref?.current?.toggleTooltip();
    }
  }, [isTooltip6Visible]);

  const loginAsGuest = () => {
    dispatch(
      fetchLoginAsGuest({
        communityId,
        deviceId: installationId
      })
    );
  };

  const signupAsGuest = () => {
    dispatch(
      fetchSignupAsGuest({
        communityId,
        deviceId: installationId,
        registerkey
      })
    );
  };

  const showTooltips = async () => {
    const hideTooltips = await AsyncStorage.getItem("hideTooltips");
    if (!token && !hideTooltips && !(isAndroid && __DEV__)) {
      if (!needRegisterKey) {
        setTimeout(() => dispatch({ type: SET_TOOLTIP3_VISIBLE }), 1000);
      } else if (majorVersionIOS >= 13) {
        setTimeout(() => dispatch({ type: SET_TOOLTIP4_VISIBLE }), 1000);
      } else if (!isElectron) {
        setTimeout(() => dispatch({ type: SET_TOOLTIP5_VISIBLE }), 1000);
      }
    }
  };

  const dispatchSelectCategory = category => {
    Keyboard.dismiss();
    dispatch({
      type: SET_SELECTED_CATEGORY,
      value: category
    });
  };

  const login = () => {
    dispatch(fetchLogin(emailOrPseudo, password, communityId));
  };

  const signUp = () => {
    dispatch(fetchSignUp(emailOrPseudo, registerkey, communityId, lang));
  };

  const {
    color5,
    color2,
    darkgrey,
    white,
    bgColor1,
    bgTransparent,
    bgWhite,
    bgColor2,
    bgColor5,
    flex1,
    rounded10,
    rounded22,
    alignItemsCenter,
    justifyContentCenter,
    textCenter,
    fs20,
    fs10,
    h44,
    p40,
    row,
    pv20,
    font,
    fontLight,
    fontBold,
    ml10,
    opacity07,
    opacity1,
    underline,
    mb10
  } = commonStyles;

  const isLoginPage = selectedCategory === 0;
  const isSignUpPage = selectedCategory === 1;

  return (
    <View style={flex1}>
      <SafeAreaView style={flex1}>
        <View style={flex1}>
          <TouchableOpacity
            style={styles.closeModal}
            onPress={() => navigation.goBack()}
          >
            <FontAwesome raised name="caret-down" size={40} color="#444041" />
          </TouchableOpacity>
          <KeyboardAvoidingView
            keyboardVerticalOffset={isiOS ? 0 : 500}
            style={[
              flex1,
              bgColor1,
              p40,
              alignItemsCenter,
              justifyContentCenter
            ]}
            behavior={isiOS ? "padding" : undefined}
          >
            {showImage ? (
              logoUri ? (
                <Image
                  style={{
                    width: SCREEN_WIDTH / 3,
                    height: SCREEN_WIDTH / 3,
                    resizeMode: "contain"
                  }}
                  source={{ uri: logoUri }}
                />
              ) : (
                <Image
                  style={{
                    width: SCREEN_WIDTH / 3,
                    height: SCREEN_WIDTH / 3
                  }}
                  source={getImage("logo")}
                />
              )
            ) : logoUri ? (
              <Text style={[fontBold, color2, fs20]}>{BUSINESS_NAME}</Text>
            ) : (
              <Text style={[fontBold, color2, fs20]}>{communityName}</Text>
            )}
            <View>
              <View style={row}>
                <Button
                  disabled={isFetching}
                  clear
                  // activeOpacity={0.7}
                  onPress={() => dispatchSelectCategory(0)}
                  containerStyle={flex1}
                  titleStyle={[
                    textCenter,
                    color5,
                    fs20,
                    opacity07,
                    isLoginPage && opacity1
                  ]}
                  buttonStyle={[bgTransparent]}
                  disabledStyle={[bgTransparent]}
                  title={t("login:login")}
                />
                <Button
                  disabled={isFetching}
                  clear
                  // activeOpacity={0.7}
                  onPress={() => dispatchSelectCategory(1)}
                  containerStyle={flex1}
                  titleStyle={[
                    textCenter,
                    color5,
                    fs20,
                    opacity07,
                    isSignUpPage && opacity1
                  ]}
                  buttonStyle={[bgTransparent]}
                  disabledStyle={[bgTransparent]}
                  title={t("login:register")}
                />
              </View>
              <View style={[alignItemsCenter, row, { height: 20 }]}>
                <TabSelector selected={isLoginPage} />
                <TabSelector selected={isSignUpPage} />
              </View>
              <View
                style={[
                  bgWhite,
                  alignItemsCenter,
                  pv20,
                  rounded10,
                  { width: SCREEN_WIDTH - 30 }
                ]}
              >
                {isSignUpPage && displayRegisterkey && (
                  <Input
                    leftIcon={
                      <SimpleLineIcons
                        name="lock"
                        color="rgba(0, 0, 0, 0.38)"
                        size={25}
                        style={{ backgroundColor: "transparent" }}
                      />
                    }
                    value={registerkey}
                    editable={!isFetching}
                    keyboardAppearance="light"
                    autoCapitalize="none"
                    autoCorrect={false}
                    secureTextEntry={true}
                    returnKeyType={"done"}
                    blurOnSubmit={true}
                    containerStyle={[
                      emailOrPseudoErrorMsg
                        ? {
                            marginTop: 10
                          }
                        : {
                            marginTop: 16
                          },
                      mb10
                    ]}
                    inputContainerStyle={{
                      borderBottomColor: "rgba(68, 64, 65, 0.38)",
                      borderBottomWidth: 1
                    }}
                    inputStyle={[ml10, fontLight]}
                    containerStyle={{
                      width: SCREEN_WIDTH - 40
                    }}
                    placeholder={t("login:neighborhoodkey")}
                    ref={registerkeyInputRef}
                    onChangeText={registerkey =>
                      dispatch({
                        type: SET_REGISTER_KEY,
                        value: registerkey
                      })
                    }
                    errorMessage={registerkeyErrorMsg}
                  />
                )}
                <View style={[row, mb10]}>
                  {!needRegisterKey && (
                    <Tooltip
                      ref={tooltip3Ref}
                      popover={
                        <Text style={[font, white]}>{t("login:tooltip3")}</Text>
                      }
                      backgroundColor={DARKGREY_COLOR}
                      toggleOnPress={false}
                      withOverlay={true}
                      height={80}
                      width={180}
                      onClose={() => {
                        setTimeout(
                          () =>
                            majorVersionIOS >= 13
                              ? dispatch({
                                  type: SET_TOOLTIP4_VISIBLE
                                })
                              : !isElectron
                              ? dispatch({
                                  type: SET_TOOLTIP5_VISIBLE
                                })
                              : dispatch({
                                  type: SET_TOOLTIP6_VISIBLE,
                                  payload: true
                                }),
                          10
                        );
                      }}
                      skipAndroidStatusBar
                    >
                      <TouchableOpacity
                        style={[
                          bgColor5,
                          alignItemsCenter,
                          justifyContentCenter,
                          {
                            height: 54,
                            width: 54,
                            marginHorizontal: 10,
                            borderRadius: 27
                          }
                        ]}
                        onPress={() =>
                          isSignUpPage
                            ? dispatch({
                                type: CLEAR_VALIDATE_GUEST
                              })
                            : loginAsGuest()
                        }
                      >
                        <Zocial name="guest" size={32} color={WHITE_COLOR} />
                      </TouchableOpacity>
                    </Tooltip>
                  )}
                  {!isElectron && <FBLogin />}
                </View>
                {(!isElectron || !needRegisterKey) && (
                  <Text style={[fontBold, darkgrey]}>
                    {isLoginPage || isElectron
                      ? t("login:or")
                      : t("login:orand")}
                  </Text>
                )}
                <Tooltip
                  ref={tooltip6Ref}
                  popover={
                    <Text style={[font, white]}>{t("login:tooltip6")}</Text>
                  }
                  backgroundColor={DARKGREY_COLOR}
                  toggleOnPress={false}
                  withOverlay={true}
                  height={80}
                  width={200}
                  onClose={() =>
                    setTimeout(
                      () =>
                        dispatch({
                          type: SET_TOOLTIP6_VISIBLE,
                          payload: false
                        }),
                      10
                    )
                  }
                  skipAndroidStatusBar
                >
                  <Input
                    leftIcon={
                      isLoginPage ? (
                        <FontAwesome
                          name="id-card-o"
                          color="rgba(0, 0, 0, 0.38)"
                          size={22}
                          style={{ backgroundColor: "transparent" }}
                        />
                      ) : (
                        <FontAwesome
                          name="envelope-o"
                          color="rgba(0, 0, 0, 0.38)"
                          size={25}
                          style={{ backgroundColor: "transparent" }}
                        />
                      )
                    }
                    value={emailOrPseudo}
                    editable={!isFetching}
                    keyboardAppearance="light"
                    autoFocus={false}
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="email-address"
                    returnKeyType={isSignUpPage ? "done" : "next"}
                    inputStyle={[ml10, fontLight]}
                    inputContainerStyle={{
                      borderBottomColor: "rgba(68, 64, 65, 0.38)",
                      borderBottomWidth: 1
                    }}
                    containerStyle={{
                      marginTop: 10,
                      width: SCREEN_WIDTH - 40
                    }}
                    placeholder={
                      isLoginPage ? t("login:emailorpseudo") : t("login:email")
                    }
                    ref={emailOrPseudoInputRef}
                    onSubmitEditing={() =>
                      isSignUpPage
                        ? dispatch({
                            type: CLEAR_VALIDATE
                          })
                        : passwordInputRef?.current?.focus()
                    }
                    onChangeText={emailOrPseudo =>
                      dispatch({
                        type: SET_EMAIL_OR_PSEUDO,
                        value: emailOrPseudo
                      })
                    }
                    errorMessage={emailOrPseudoErrorMsg}
                  />
                </Tooltip>
                {isLoginPage && (
                  <Input
                    leftIcon={
                      <SimpleLineIcons
                        name="lock"
                        color="rgba(0, 0, 0, 0.38)"
                        size={25}
                        style={{ backgroundColor: "transparent" }}
                      />
                    }
                    value={password}
                    editable={!isFetching}
                    keyboardAppearance="light"
                    autoCapitalize="none"
                    autoCorrect={false}
                    secureTextEntry={true}
                    returnKeyType={"done"}
                    blurOnSubmit={true}
                    inputContainerStyle={{
                      borderBottomColor: "rgba(68, 64, 65, 0.38)",
                      borderBottomWidth: 1
                    }}
                    inputStyle={[ml10, fontLight]}
                    containerStyle={{
                      width: SCREEN_WIDTH - 40
                    }}
                    placeholder={t("login:password")}
                    ref={passwordInputRef}
                    onChangeText={password =>
                      dispatch({
                        type: SET_PASSWORD,
                        value: password
                      })
                    }
                    onSubmitEditing={() =>
                      dispatch({
                        type: CLEAR_VALIDATE
                      })
                    }
                    errorMessage={tokenErrorMsg || passwordErrorMsg}
                  />
                )}
                {isLoginPage ? (
                  <Button
                    type="clear"
                    titleStyle={[color5, fontBold, fs10, underline]}
                    containerStyle={{ marginTop: 16 }}
                    title={t("login:forgotpassword")}
                    onPress={() =>
                      dispatch({
                        type: CLEAR_FORGET_PWD_VALIDATE
                      })
                    }
                  >
                    {t("login:forgotpassword")}
                  </Button>
                ) : (
                  <Button
                    type="clear"
                    titleStyle={[color5, fontBold, fs10, underline]}
                    containerStyle={{ marginTop: 16 }}
                    title={t("login:cgu")}
                    onPress={() => navigation.navigate("CGUScreen")}
                  >
                    {t("login:cgu")}
                  </Button>
                )}
                <Button
                  buttonStyle={[bgColor2, h44, rounded22, { width: 140 }]}
                  containerStyle={[{ marginTop: 16 }]}
                  activeOpacity={0.8}
                  title={isLoginPage ? t("button:signin") : t("button:signup")}
                  onPress={() =>
                    dispatch({
                      type: CLEAR_VALIDATE
                    })
                  }
                  titleStyle={[white, fontBold, { fontSize: 16 }]}
                  loading={isFetching}
                  disabled={isFetching}
                />
              </View>
            </View>
          </KeyboardAvoidingView>
        </View>
      </SafeAreaView>
    </View>
  );
};

const LoginModal = memo(Login);
export default LoginModal;

const styles = StyleSheet.create({
  selected: {
    position: "absolute",
    borderRadius: 50,
    height: 0,
    width: 0,
    top: -5,
    borderRightWidth: 70,
    borderBottomWidth: 70,
    borderColor: "white",
    backgroundColor: "white"
  },
  closeModal: {
    position: "absolute",
    top: 10,
    width: "100%",
    alignItems: "center",
    zIndex: 2
  }
});
