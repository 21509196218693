import { combineReducers } from "redux";
import loginReducer from "./login";
import communityReducer from "./community";
import tokenReducer from "./token";
import meteoReducer from "./meteo";
import adReducer from "./ad";
import templateReducer from "./template";
import liveReducer from "./live";
import profileReducer from "./profile";
import chatReducer from "./chat";
import settingsReducer from "./settings";
import globalInformationsReducer from "./globalInformations";
import entitiesReducer from "./entities";
import eventInterestedUsersReducer from "./eventInterestedUsers";
import errorReducer from "./error";
import urlscanReducer from "./urlscan";
import postReducer from "./post";
import eventReducer from "./event";
import captureReducer from "./capture";

export default function createReducers() {
  const appReducer = combineReducers({
    errorReducer,
    loginReducer,
    communityReducer,
    tokenReducer,
    meteoReducer,
    adReducer,
    templateReducer,
    liveReducer,
    profileReducer,
    chatReducer,
    settingsReducer,
    entitiesReducer,
    globalInformationsReducer,
    eventInterestedUsersReducer,
    urlscanReducer,
    postReducer,
    eventReducer,
    captureReducer
  });
  return appReducer;
}
