import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import { useHeaderHeight } from "@react-navigation/elements";
import React, { memo, useRef } from "react";
import {
  ActivityIndicator,
  Image,
  ScrollView,
  Text,
  TouchableHighlight,
  TouchableOpacity,
  View
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { fetchUpdateCommunityMapPhotoUri } from "../actions/community";
import { getImage } from "../assets/Images";
import { BUSINESS_NAME } from "../config/constants.js";
import { t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  DARKGREY_COLOR,
  isNative,
  SCREEN_HEIGHT,
  SCREEN_WIDTH
} from "../styles/commonStyles";
import { openBrowser, openURL } from "../utils/UrlUtil";
import MapRoute from "./MapRoute";
import MapRouteImage from "./MapRouteImage";

const CommunityDetails = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const mapRouteRef = useRef();
  const headerHeight = useHeaderHeight();

  const { isFetching, currentCommunity } = useSelector(
    state => state.communityReducer
  );
  const { lang } = useSelector(state => state.globalInformationsReducer);
  const { mapPhotoUri, files } = useSelector(state => state.captureReducer);

  const {
    _id,
    name,
    address,
    zipCode,
    city,
    phone,
    fax,
    mail,
    webSiteUrl,
    qrcodeUri,
    pdfUri,
    mapUri,
    mapSmallUri,
    loc,
    mapPhotoUri: photoUri,
    building,
    residence,
    district
  } = currentCommunity || {};

  const {
    color3,
    color2,
    color5,
    darkgrey,
    bgWhite,
    font,
    fontBold,
    bgColor1,
    justifyContentCenter,
    spaceBetween,
    row,
    fs20,
    mt5,
    mt10,
    mv10,
    mt20,
    p20,
    pv20,
    mb10,
    mb20,
    shadowGrey,
    textCenter,
    flex1,
    flex2,
    contain,
    w100p,
    h100p,
    w100,
    h100,
    alignItemsFlexEnd
  } = commonStyles;

  return (
    <View style={[bgColor1, w100p, { height: SCREEN_HEIGHT - headerHeight }]}>
      {isFetching ? (
        <ActivityIndicator
          style={[flex1, justifyContentCenter]}
          size="large"
          color={COLOR2}
        />
      ) : (
        <ScrollView style={[bgColor1]} showsVerticalScrollIndicator={false}>
          <View style={[p20, bgWhite, mt5, shadowGrey]}>
            <Text style={[fontBold, color2, fs20, textCenter]}>
              {BUSINESS_NAME}
            </Text>
            <Text style={[fontBold, darkgrey, fs20, textCenter, mt10]}>
              {name}
            </Text>
            <View style={[flex1, row, mt20, spaceBetween]}>
              <View style={flex2}>
                {address && (
                  <Text
                    style={[font, color5, { fontSize: 17, flexWrap: "wrap" }]}
                  >
                    {address}
                  </Text>
                )}
                <Text style={[font, color5, mt10, { fontSize: 17 }]}>
                  {zipCode} {city}
                </Text>

                {building && (
                  <Text style={[font, color5, mt10, { fontSize: 17 }]}>
                    {`${t("infos:building")} ${building}`}
                  </Text>
                )}

                {residence && (
                  <Text style={[font, color5, mt10, { fontSize: 17 }]}>
                    {`${t("infos:residence")} ${residence}`}
                  </Text>
                )}

                {district && (
                  <Text style={[font, color5, mt10, { fontSize: 17 }]}>
                    {`${t("infos:district")} ${district}`}
                  </Text>
                )}

                {phone && (
                  <Text style={[font, mt10, darkgrey, { fontSize: 15 }]}>
                    {t("infos:tel")}{" "}
                    <Text
                      style={[font, mt10, color3, { fontSize: 15 }]}
                      onPress={() => openURL(dispatch, "telprompt:" + phone)}
                    >
                      {phone}
                    </Text>
                  </Text>
                )}

                {fax && (
                  <Text style={[font, mt10, darkgrey, { fontSize: 15 }]}>
                    {t("infos:fax")}{" "}
                    <Text style={[font, mt10, color3, { fontSize: 15 }]}>
                      {fax}
                    </Text>
                  </Text>
                )}
              </View>

              <View
                style={[flex1, justifyContentCenter, h100, alignItemsFlexEnd]}
              >
                {qrcodeUri ? (
                  <TouchableHighlight
                    style={[h100p, w100p]}
                    onPress={() =>
                      pdfUri && navigation.navigate("PdfWelcomeScreen")
                    }
                  >
                    <Image
                      style={[h100, w100, contain]}
                      source={{ uri: qrcodeUri }}
                    />
                  </TouchableHighlight>
                ) : (
                  <Image
                    style={[h100, w100p, contain]}
                    source={getImage("logo")}
                  />
                )}
              </View>
            </View>
            {mail && (
              <Text style={[font, mt20, darkgrey, { fontSize: 12 }]}>
                {t("infos:contact")}{" "}
                <Text
                  style={[font, mt20, color2, { fontSize: 12 }]}
                  onPress={() => openURL(dispatch, "mailto:" + mail)}
                >
                  {mail}
                </Text>
              </Text>
            )}
            {webSiteUrl && (
              <Text style={[font, mt20, darkgrey, { fontSize: 12 }]}>
                {t("infos:website")}{" "}
                <Text
                  style={[font, mt20, color2, { fontSize: 12 }]}
                  onPress={() => openBrowser(dispatch, webSiteUrl)}
                >
                  {webSiteUrl}
                </Text>
              </Text>
            )}
          </View>
          {loc && (isNative || photoUri) && (
            <View style={[pv20, bgWhite, mt10, shadowGrey]}>
              <Text style={[font, color5, fs20, mb20, textCenter]}>
                {t("infos:location")}
              </Text>
              <MapRoute
                ref={mapRouteRef}
                uri={photoUri}
                longitude={loc.coordinates[0]}
                latitude={loc.coordinates[1]}
                title={BUSINESS_NAME + " " + name}
                lang={lang}
              />
              {Boolean(mapPhotoUri) && (
                <MapRouteImage
                  uri={mapPhotoUri}
                  callback={() =>
                    dispatch(
                      fetchUpdateCommunityMapPhotoUri({
                        _id,
                        mapPhotoUri,
                        files
                      })
                    )
                  }
                />
              )}
            </View>
          )}
          {mapUri && mapSmallUri && (
            <View style={[flex1, p20, bgWhite, mv10, shadowGrey]}>
              <Text style={[font, color5, fs20, mb10, textCenter]}>
                {t("infos:map")}
              </Text>

              <View
                style={[
                  {
                    height: 300
                  },
                  w100p
                ]}
              >
                <Image
                  style={[
                    {
                      height: 300
                    },
                    w100p,
                    contain
                  ]}
                  source={{ uri: mapSmallUri }}
                />
                <TouchableOpacity
                  style={{
                    width: 160,
                    position: "absolute",
                    bottom: 60,
                    left: SCREEN_WIDTH / 2 - 100
                  }}
                >
                  <MaterialIcons.Button
                    name="zoom-in"
                    size={30}
                    color={DARKGREY_COLOR}
                    backgroundColor={"rgba(255, 255, 255, 0.4)"}
                    borderRadius={25}
                    onPress={() =>
                      navigation.navigate("ImageZoomScreen", { uri: mapUri })
                    }
                  >
                    <Text style={[font]}>{t("infos:clickimage")}</Text>
                  </MaterialIcons.Button>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </ScrollView>
      )}
    </View>
  );
};

const MemoizedCommunityDetails = memo(CommunityDetails);
export default MemoizedCommunityDetails;
