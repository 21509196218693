import { useNavigation } from "@react-navigation/core";
import * as Location from "expo-location";
import React, { memo, useEffect, useRef, useState } from "react";
import { ActivityIndicator, FlatList, StyleSheet, View } from "react-native";
import { Button, ListItem, SearchBar } from "react-native-elements";
import { Icon } from "react-native-elements/dist/icons/Icon";
import { GiftedAvatar } from "react-native-gifted-chat";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  fetchGetCommunityDetails,
  fetchSearchCummunities
} from "../actions/community";
import {
  CLEAR_COMMUNITIES,
  SET_DROP_DOWN_ALERT_ERROR,
  SET_DROP_DOWN_ALERT_INFO
} from "../actions/types";
import { getCommunityReducer } from "../selectors";
import { t } from "../services/i18n";
import commonStyles, { COLOR2, isWeb } from "../styles/commonStyles";
import { openBrowser } from "../utils/UrlUtil";

const getSelector = createSelector(
  [getCommunityReducer],
  ({
    isFetchingList,
    communities,
    communitiesHasNextPage,
    communitiesNextPage,
    currentCommunity
  }) => ({
    isFetchingList,
    communities,
    communitiesHasNextPage,
    communitiesNextPage,
    currentCommunity
  })
);

const CommunitiesModal = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  let {
    isFetchingList,
    communities,
    communitiesHasNextPage,
    communitiesNextPage,
    currentCommunity
  } = useSelector(getSelector, shallowEqual);

  const [search, setSearch] = useState("");
  const [
    onEndReachedCalledDuringMomentum,
    setOnEndReachedCalledDuringMomentum
  ] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  communities = Object.values(communities);
  const communitiesLength = communities.length;

  useEffect(() => {
    dispatch(
      fetchSearchCummunities({
        page: 1
      })
    );
    return () =>
      dispatch({
        type: CLEAR_COMMUNITIES
      });
  }, []);

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevCommunitiesLength = usePrevious(communitiesLength);

  useEffect(() => {
    if (!communitiesLength || communitiesLength > prevCommunitiesLength) {
      setOnEndReachedCalledDuringMomentum(false);
    }
  }, [prevCommunitiesLength, communitiesLength]);

  const prevCurrentCommunity = usePrevious(currentCommunity);

  useEffect(() => {
    if (prevCurrentCommunity && currentCommunity !== prevCurrentCommunity) {
      navigation.navigate("LandingScreen", {
        communityUri: currentCommunity.customUri
          ? currentCommunity.customUri
          : currentCommunity._id
      });
    }
  }, [prevCurrentCommunity, currentCommunity]);

  const {
    font,
    m20,
    flex1,
    w100p,
    bgWhite,
    red,
    mt10,
    overflowHidden,
    justifyContentCenter,
    textCenter,
    p10,
    bgColor5,
    white
  } = commonStyles;

  const renderItem = ({ item }) => (
    <ListItem
      bottomDivider
      onPress={() => {
        if (item._id === currentCommunity._id) {
          navigation.goBack();
        } else {
          dispatch(fetchGetCommunityDetails(item._id));
        }
      }}
    >
      <GiftedAvatar
        user={{
          name: item.name,
          avatar: item.logoUri
        }}
      />
      <ListItem.Content>
        <ListItem.Title style={font}>{item.name}</ListItem.Title>
        <ListItem.Subtitle>{`${item.zipCode ? item.zipCode : ""} ${
          item?.city ? item.city : ""
        }`}</ListItem.Subtitle>
      </ListItem.Content>
      <ListItem.Chevron />
    </ListItem>
  );

  const _getLocationAsync = async () => {
    try {
      setIsFetching(true);
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        dispatch({
          type: SET_DROP_DOWN_ALERT_INFO,
          info: "locationinfo"
        });
      } else {
        const location = await Location.getCurrentPositionAsync({
          accuracy: Location.Accuracy.Low
        });

        dispatch(
          fetchSearchCummunities({
            page: 1,
            latitude: location.coords.latitude,
            longitude: location.coords.longitude
          })
        );
      }
    } catch ({ message }) {
      dispatch({
        type: SET_DROP_DOWN_ALERT_ERROR,
        error: "locationerror",
        message
      });
    } finally {
      setIsFetching(false);
    }
  };

  const _displayLoading = () => {
    if (isFetching || isFetchingList) {
      return (
        <View style={styles.loading_container}>
          <ActivityIndicator size="large" color={COLOR2} />
        </View>
      );
    }
  };

  return (
    <View style={[flex1, m20, justifyContentCenter]}>
      <View style={[flex1, { maxHeight: 600 }]}>
        <View style={[flex1, { borderRadius: 3 }, bgWhite, overflowHidden]}>
          <SearchBar
            inputStyle={isWeb && { paddingLeft: 10 }}
            placeholder={t("button:search")}
            onChangeText={search => {
              setSearch(search);
              dispatch(
                fetchSearchCummunities({
                  page: 1,
                  search
                })
              );
            }}
            value={search}
          />
          <ListItem.Title
            bottomDivider
            style={[font, textCenter, bgColor5, white, p10]}
          >
            {t("login:selectcommunity")}
          </ListItem.Title>
          <ListItem bottomDivider onPress={_getLocationAsync}>
            <Icon name="my-location" type="material" color={COLOR2} />
            <ListItem.Content>
              <ListItem.Title style={font}>{t("button:nearby")}</ListItem.Title>
            </ListItem.Content>
          </ListItem>
          {communities && (
            <FlatList
              showsVerticalScrollIndicator={false}
              style={[flex1, w100p]}
              keyExtractor={item => item._id}
              data={communities}
              renderItem={renderItem}
              onEndReachedThreshold={0.5}
              onEndReached={() => {
                if (
                  communitiesHasNextPage &&
                  !onEndReachedCalledDuringMomentum
                ) {
                  setOnEndReachedCalledDuringMomentum(true);
                  dispatch(
                    fetchSearchCummunities({
                      page: communitiesNextPage,
                      search
                    })
                  );
                }
              }}
            />
          )}
          <ListItem
            // bottomDivider
            topDivider
            onPress={() =>
              openBrowser(
                dispatch,
                "https://www.hapicolibri.fr/enregistrement-voisinage"
              )
            }
          >
            <Icon name="add-to-list" type="entypo" color={COLOR2} />
            <ListItem.Content>
              <ListItem.Title style={[font]}>
                {t("login:createcommunity")}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        </View>
        {_displayLoading()}
        <Button
          buttonStyle={[w100p, bgWhite]}
          titleStyle={[font, red]}
          containerStyle={[mt10]}
          onPress={() => navigation.goBack()}
          title={t("button:cancel")}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  loading_container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 100,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center"
  }
});

const MemoizedCommunitiesModal = memo(CommunitiesModal);
export default MemoizedCommunitiesModal;
