import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import * as Location from "expo-location";
import React, { memo, useEffect, useRef, useState } from "react";
import { SafeAreaView } from "react-native";
import { TouchableOpacity } from "react-native";
import { ActivityIndicator, Text, View } from "react-native";
import MapView from "react-native-maps";
import MapViewCallout from "react-native-web-maps/src/Callout";
import MapViewMarker from "react-native-web-maps/src/Marker";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetAdDetails, fetchGetAdsLocation } from "../actions/ad";
import {
  SET_DROP_DOWN_ALERT_ERROR,
  SET_DROP_DOWN_ALERT_INFO
} from "../actions/types";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, { COLOR2, isElectron } from "../styles/commonStyles";

const DEFAULT_LATITUDE_DELTA = 0.00722;
const DEFAULT_LONGITUDE_DELTA = 0.0021;

const DEFAULT_LATITUDE = 45.898698;
const DEFAULT_LONGITUDE = 6.12856;

const AdsMap = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const mapRef = useRef();
  const markersRefs = useRef({});
  const userLocationMarkerRef = useRef();
  const communityMarkerRef = useRef();

  const [isFetching, setIsFetching] = useState(false);
  const [initialRegion, setInitialRegion] = useState(null);
  const [region, setRegion] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);

  const { communityDetails: community } = useSelector(
    state => state.communityReducer
  );
  const { adsLocations = [], ad } = useSelector(state => state.adReducer);

  useEffect(() => {
    if (ad) {
      const { cityUri, customUri: adUri } = ad;
      navigation.navigate("AdScreen", {
        cityUri,
        adUri
      });
    }
  }, [ad]);

  // useEffect(() => {
  //   for (const markerRef of Object.values(markersRefs.current)) {
  //     markerRef?.showCallout();
  //   }
  //   mapRef.current?.animateToRegion(region, 1000);
  // }, [Object.values(markersRefs.current).length]);

  const setCommunityInitialRegion = () => {
    if (community) {
      const { loc } = community;
      setInitialRegion({
        latitudeDelta: DEFAULT_LATITUDE_DELTA,
        longitudeDelta: DEFAULT_LONGITUDE_DELTA,
        longitude: loc.coordinates[0],
        latitude: loc.coordinates[1]
      });
      setRegion({
        longitude: loc.coordinates[0],
        latitude: loc.coordinates[1]
      });
    } else {
      setInitialRegion({
        latitudeDelta: DEFAULT_LATITUDE_DELTA,
        longitudeDelta: DEFAULT_LONGITUDE_DELTA,
        latitude: DEFAULT_LATITUDE,
        longitude: DEFAULT_LONGITUDE
      });
      setRegion({
        longitude: DEFAULT_LATITUDE,
        latitude: DEFAULT_LONGITUDE
      });
    }
  };

  const _getLocationAsync = async () => {
    try {
      setIsFetching(true);
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        dispatch({
          type: SET_DROP_DOWN_ALERT_INFO,
          info: "locationinfo"
        });
        setCommunityInitialRegion();
      } else {
        const location = await Location.getCurrentPositionAsync({
          accuracy: Location.Accuracy.Low
        });
        setInitialRegion({
          latitudeDelta: DEFAULT_LATITUDE_DELTA,
          longitudeDelta: DEFAULT_LONGITUDE_DELTA,
          latitude: location.coords.latitude,
          longitude: location.coords.longitude
        });
        setRegion({
          latitude: location.coords.latitude,
          longitude: location.coords.longitude
        });
        setCurrentLocation({
          latitude: location.coords.latitude,
          longitude: location.coords.longitude
        });
      }
    } catch ({ message }) {
      dispatch({
        type: SET_DROP_DOWN_ALERT_ERROR,
        error: "locationerror",
        message
      });
      setCommunityInitialRegion();
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    dispatch(fetchGetAdsLocation(1, null));
    if (isElectron) {
      setCommunityInitialRegion();
    } else {
      _getLocationAsync();
    }
  }, []);

  const onRegionChange = region => {
    setRegion({ region });
  };

  const { w100p, flex1, font, alignItemsCenter, justifyContentCenter } =
    commonStyles;

  return initialRegion ? (
    <View style={flex1}>
      <SafeAreaView
        style={[
          w100p,
          alignItemsCenter,
          justifyContentCenter,
          {
            position: "absolute",
            bottom: 50,
            left: 0,
            zIndex: 3
          }
        ]}
      >
        {!isElectron && (
          <TouchableOpacity
            style={{
              position: "absolute",
              left: 40,
              zIndex: 2
            }}
            onPress={async () => await _getLocationAsync()}
            disabled={isFetching}
          >
            {isFetching ? (
              <ActivityIndicator size="large" color={COLOR2} />
            ) : (
              <MaterialIcons name="my-location" size={40} color={COLOR2} />
            )}
          </TouchableOpacity>
        )}
      </SafeAreaView>
      <MapView
        ref={mapRef}
        style={[flex1]}
        initialRegion={initialRegion}
        region={region}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          rotateControl: false,
          scaleControl: false
        }}
        onRegionChangeComplete={onRegionChange}
        showsUserLocation={Boolean(currentLocation)}
      >
        {adsLocations &&
          adsLocations.map(ad => {
            const { _id, loc } = ad;
            if (loc) {
              return (
                <MapViewMarker
                  ref={element => (markersRefs.current[_id] = element)}
                  key={_id}
                  coordinate={{
                    longitude: loc.coordinates[0],
                    latitude: loc.coordinates[1]
                  }}
                  title={getTranslatedProperty(ad, "title")}
                  description={getTranslatedProperty(ad, "description")}
                  icon={{
                    url: "https://mt.google.com/vt/icon?psize=30&font=fonts/arialuni_t.ttf&color=ff00ff00&name=icons/spotlight/spotlight-waypoint-a.png&ax=43&ay=48&text=•"
                  }}
                  onPress={() => markersRefs.current[_id].showCallout()}
                >
                  <MapViewCallout>
                    <TouchableOpacity
                      onPress={() => dispatch(fetchGetAdDetails({ id: _id }))}
                    >
                      <Text style={font}>
                        {getTranslatedProperty(ad, "title")}
                      </Text>
                    </TouchableOpacity>
                  </MapViewCallout>
                </MapViewMarker>
              );
            }
          })}
        {currentLocation && (
          <MapViewMarker
            ref={userLocationMarkerRef}
            coordinate={{
              longitude: currentLocation.longitude,
              latitude: currentLocation.latitude
            }}
            title={t("infos:mylocation")}
            icon={{
              url: "https://mt.google.com/vt/icon?color=ff004C13&name=icons/spotlight/spotlight-waypoint-blue.png"
            }}
            onPress={() => userLocationMarkerRef.current?.showCallout()}
          >
            <MapViewCallout>
              <Text style={font}>{t("infos:mylocation")}</Text>
            </MapViewCallout>
          </MapViewMarker>
        )}
        {community?.loc && (
          <MapViewMarker
            ref={communityMarkerRef}
            coordinate={{
              longitude: community.loc.coordinates[0],
              latitude: community.loc.coordinates[1]
            }}
            title={community.name}
            onPress={() => communityMarkerRef.current?.showCallout()}
            icon={{
              url: community.subscribe
                ? "https://mt.google.com/vt/icon/name=icons/spotlight/home_L_8x.png"
                : "https://mt.google.com/vt/icon/name=icons/spotlight/home_L_8x.png"
            }}
          >
            <MapViewCallout
              onPress={() => navigation.navigate("CommunityDetailsScreen")}
            >
              <Text style={font}>{community.name}</Text>
            </MapViewCallout>
          </MapViewMarker>
        )}
      </MapView>
    </View>
  ) : (
    <View style={[flex1, alignItemsCenter, justifyContentCenter]}>
      <ActivityIndicator size="large" color={COLOR2} />
    </View>
  );
};

const MemoizedAdsMap = memo(AdsMap);
export default MemoizedAdsMap;
