export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";
export const SERVER_IN_MAINTENANCE = "SERVER_IN_MAINTENANCE";
export const ON_APP_BLUR = "ON_APP_BLUR";
export const ON_APP_FOCUS = "ON_APP_FOCUS";

export const HIDE_SPLASH_SCREEN = "HIDE_SPLASH_SCREEN";
export const ADD_DEEP_LINK = "ADD_DEEP_LINK";
export const CLEAR_DEEP_LINK_EVENT = "CLEAR_DEEP_LINK_EVENT";
export const SET_DEEP_LINK_TOKEN = "SET_DEEP_LINK_TOKEN";

export const SET_SID = "SET_SID";

export const WS_DISCONNECTED = "WS_DISCONNECTED";
export const WS_CONNECTED = "WS_CONNECTED";
export const WS_EVENT = "WS_EVENT";

export const FETCH_FAILURE = "FETCH_FAILURE";

// WS types
export const CONNECTION = "CONNECTION";
export const CHAT = "CHAT";
export const LIVE = "LIVE";
export const LIVE_UPDATE = "LIVE_UPDATE";
export const LIVE_INTERESTED = "LIVE_INTERESTED";
export const LIVE_UNINTERESTED = "LIVE_UNINTERESTED";
export const LIVE_DELETE = "LIVE_DELETE";
export const OFFLINE = "OFFLINE";
export const ONLINE = "ONLINE";
export const UPDATE_GLOBAL_NOT_READ = "UPDATE_GLOBAL_NOT_READ";
export const RESET_NOT_READ = "RESET_NOT_READ";
export const NEW_CATEGORIES_TEMPLATES = "NEW_CATEGORIES_TEMPLATES";
export const RELOAD_CHAT_USERS = "RELOAD_CHAT_USERS";
export const ADD_GOODDEAL = "ADD_GOODDEAL";
export const UPDATE_GOODDEAL = "UPDATE_GOODDEAL";
export const DELETE_GOODDEAL = "DELETE_GOODDEAL";
export const CLEAR_AD = "CLEAR_AD";

export const TYPE_ACTIVITY = "Activity";
export const TYPE_GOODDEAL_ACTIVITY = "GooddealActivity";
export const TYPE_TEMPLATE = "Template";

export const FETCH_GET_COMMUNITY_DETAILS = "FETCH_GET_COMMUNITY_DETAILS";
export const SET_COMMUNITY_DETAILS_FAILURE = "SET_COMMUNITY_DETAILS_FAILURE";
export const SET_COMMUNITY_DETAILS = "SET_COMMUNITY_DETAILS";
export const FETCH_UPDATE_COMMUNITY_MAP_PHOTO_URI =
  "FETCH_UPDATE_COMMUNITY_MAP_PHOTO_URI";
export const SET_UPDATE_COMMUNITY_MAP_PHOTO_URI_RESULT =
  "SET_UPDATE_COMMUNITY_MAP_PHOTO_URI_RESULT";
export const FETCH_SEARCH_COMMUNITIES = "FETCH_SEARCH_COMMUNITIES";
export const SET_SEARCH_COMMUNITIES_RESULT = "SET_SEARCH_COMMUNITIES_RESULT";
export const FETCH_GET_CURRENT_COMMUNITY = "FETCH_GET_CURRENT_COMMUNITY";
export const SET_CURRENT_COMMUNITY = "SET_CURRENT_COMMUNITY";
export const SET_CURRENT_COMMUNITY_FAILURE = "SET_CURRENT_COMMUNITY_FAILURE";
export const FETCH_GET_NEAR_COMMUNITIES = "FETCH_GET_NEAR_COMMUNITIES";
export const SET_NEAR_COMMUNITIES = "SET_NEAR_COMMUNITIES";
export const CLEAR_COMMUNITIES = "CLEAR_COMMUNITIES";
export const SET_REGION_COORDINATES = "SET_REGION_COORDINATES";

export const FETCH_GET_CITIES = "FETCH_GET_CITIES";
export const SET_CITIES = "SET_CITIES";
export const SET_CITIES_FAILURE = "SET_CITIES_FAILURE";

// LOGIN
export const FETCH_LOGIN = "FETCH_LOGIN";
export const FETCH_LOGIN_WITH_FB = "FETCH_LOGIN_WITH_FB";
export const FETCH_LOGIN_WITH_APPLE = "FETCH_LOGIN_WITH_APPLE";
export const FETCH_LOGIN_USING_TOKEN = "FETCH_LOGIN_USING_TOKEN";
export const LOGIN_WITH_TOKEN_RESULT = "LOGIN_WITH_TOKEN_RESULT";
export const FETCH_LOGIN_AS_GUEST = "FETCH_LOGIN_AS_GUEST";
export const FETCH_SIGNUP = "FETCH_SIGNUP";
export const FETCH_SIGNUP_WITH_FB = "FETCH_SIGNUP_WITH_FB";
export const FETCH_SIGNUP_WITH_APPLE = "FETCH_SIGNUP_WITH_APPLE";
export const FETCH_SIGNUP_AS_GUEST = "FETCH_SIGNUP_AS_GUEST";
export const SIGNUP_RESULT = "SIGNUP_RESULT";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const SET_EMAIL_OR_PSEUDO = "SET_EMAIL_OR_PSEUDO";
export const SET_PASSWORD = "SET_PASSWORD";
export const VALIDATE_INPUTS = "VALIDATE_INPUTS";
export const VALIDATE_FB_INPUTS = "VALIDATE_FB_INPUTS";
export const VALIDATE_APPLE_INPUTS = "VALIDATE_APPLE_INPUTS";
export const VALIDATE_FORGET_PWD_INPUT = "VALIDATE_FORGET_PWD_INPUT";
export const VALIDATE_GUEST_INPUT = "VALIDATE_GUEST_INPUT";
export const CLEAR_VALIDATE = "CLEAR_VALIDATE";
export const CLEAR_VALIDATE_FB = "CLEAR_VALIDATE_FB";
export const CLEAR_VALIDATE_APPLE = "CLEAR_VALIDATE_APPLE";
export const CLEAR_FORGET_PWD_VALIDATE = "CLEAR_FORGET_PWD_VALIDATE";
export const CLEAR_VALIDATE_GUEST = "CLEAR_VALIDATE_GUEST";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_REGISTER_KEY = "SET_REGISTER_KEY";
export const FETCH_FORGET_PASSWORD = "FETCH_FORGET_PASSWORD";
export const FORGET_PASSWORD_RESULT = "FORGET_PASSWORD_RESULT";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";
export const SET_TOOLTIP3_VISIBLE = "SET_TOOLTIP3_VISIBLE";
export const SET_TOOLTIP4_VISIBLE = "SET_TOOLTIP4_VISIBLE";
export const SET_TOOLTIP5_VISIBLE = "SET_TOOLTIP5_VISIBLE";
export const SET_TOOLTIP6_VISIBLE = "SET_TOOLTIP6_VISIBLE";
// TODO: remove SET_COMMUNITY
export const SET_COMMUNITY = "SET_COMMUNITY";
export const SET_CGU_CHECKED = "SET_CGU_CHECKED";
export const SET_LOGIN_WITH_FB_RESULT = "SET_LOGIN_WITH_FB_RESULT";
export const REGISTER_FOR_PUSH_NOTIF = "REGISTER_FOR_PUSH_NOTIF";
export const CLEAR_REGISTER_PUSH_NOTIFICATIONS =
  "CLEAR_REGISTER_PUSH_NOTIFICATIONS";
export const SET_STRATEGY = "SET_STRATEGY";
export const SET_INITIAL_URL = "SET_INITIAL_URL";
export const SET_INITIAL_LINK = "SET_INITIAL_LINK";

export const CLEAR_INITIAL_LINK = "CLEAR_INITIAL_LINK";
export const SET_MISSING_EMAIL_ERROR = "SET_MISSING_EMAIL_ERROR";
export const CLEAR_LOGIN = "CLEAR_LOGIN";

export const CHANGE_CUSTOMER_TARGET = "CHANGE_CUSTOMER_TARGET";

// PROFILE
export const FETCH_GET_USER = "FETCH_GET_USER";
export const SET_USER_FAILURE = "SET_USER_FAILURE";
export const FETCH_GET_HOBBIES = "FETCH_GET_HOBBIES";
export const SET_HOBBIES = "SET_HOBBIES";
export const SET_HOBBIES_FAILURE = "SET_HOBBIES_FAILURE";
export const SET_PROFILE_DETAILS = "SET_PROFILE_DETAILS";
export const FETCH_UPDATE_PROFILE = "FETCH_UPDATE_PROFILE";
export const FETCH_UPDATE_BOOKING = "FETCH_UPDATE_BOOKING";
export const FETCH_GET_PROFILE = "FETCH_GET_PROFILE";
export const CLEAR_PHOTO = "CLEAR_PHOTO";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const CLEAR_PROFILE_VALIDATE = "CLEAR_PROFILE_VALIDATE";
export const SET_VALIDATE_FALSE = "SET_VALIDATE_FALSE";
export const VALIDATE_PROFILE_INPUTS = "VALIDATE_PROFILE_INPUTS";
export const SET_LOCATION_ID = "SET_LOCATION_ID";
export const SET_DATE_RANGE = "SET_DATE_RANGE";
export const SET_PHOTO_URI = "SET_PHOTO_URI";
export const SET_PSEUDO = "SET_PSEUDO";
export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const SET_LAST_NAME = "SET_LAST_NAME";
export const SET_BIRTHDATE = "SET_BIRTHDATE";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_ZIP_CODE = "SET_ZIP_CODE";
export const SET_CITY = "SET_CITY";
export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE";
export const SET_PHONE = "SET_PHONE";
export const SET_FLOOR = "SET_FLOOR";
export const SET_APARTMENT_NUMBER = "SET_APARTMENT_NUMBER";
export const SET_BUILDING_ACCESS = "SET_BUILDING_ACCESS";
export const TOGGLE_CONFIRM_ADDRESS = "TOGGLE_CONFIRM_ADDRESS";
export const SET_JOB = "SET_JOB";
export const SET_BUILDING = "SET_BUILDING";
export const SET_RESIDENCE = "SET_RESIDENCE";
export const SET_DISTRICT = "SET_DISTRICT";
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_USER_HOBBIES = "SET_USER_HOBBIES";
export const UPDATE_AUTOMATICALLY_HOBBIES = "UPDATE_AUTOMATICALLY_HOBBIES";
export const NOT_UPDATE_AUTOMATICALLY_HOBBIES =
  "NOT_UPDATE_AUTOMATICALLY_HOBBIES";
export const FETCH_GET_USER_PROFILE = "FETCH_GET_USER_PROFILE";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_GENDER = "SET_GENDER";
export const FETCH_BLOCK_USER = "FETCH_BLOCK_USER";
export const SET_BLOCK_USER_RESULT = "SET_BLOCK_USER_RESULT";
export const FETCH_UNBLOCK_USER = "FETCH_UNBLOCK_USER";
export const SET_UNBLOCK_USER_RESULT = "SET_UNBLOCK_USER_RESULT";
export const FETCH_FLAG_INAPPROPRIATE_CONTENT =
  "FETCH_FLAG_INAPPROPRIATE_CONTENT";
export const SET_FLAG_INAPPROPRIATE_CONTENT_RESULT =
  "SET_FLAG_INAPPROPRIATE_CONTENT_RESULT";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const SET_DATE_INVALID = "SET_DATE_INVALID";
export const CLEAR_VALIDATE_PSEUDO = "CLEAR_VALIDATE_PSEUDO";
export const VALIDATE_PSEUDO = "VALIDATE_PSEUDO";
export const CLEAR_VALIDATE_FIRST_NAME = "CLEAR_VALIDATE_FIRST_NAME";
export const VALIDATE_FIRST_NAME = "VALIDATE_FIRST_NAME";
export const CLEAR_VALIDATE_LAST_NAME = "CLEAR_VALIDATE_LAST_NAME";
export const VALIDATE_LAST_NAME = "VALIDATE_LAST_NAME";
export const CLEAR_VALIDATE_BIRTHDATE = "CLEAR_VALIDATE_BIRTHDATE";
export const VALIDATE_BIRTHDATE = "VALIDATE_BIRTHDATE";
export const CLEAR_VALIDATE_PHONE = "CLEAR_VALIDATE_PHONE";
export const VALIDATE_PHONE = "VALIDATE_PHONE";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_NEW_PASSWORD_CONFIRMATION = "SET_NEW_PASSWORD_CONFIRMATION";
export const VALIDATE_NEW_PASSWORD = "VALIDATE_NEW_PASSWORD";
export const VALIDATE_NEW_PASSWORD_CONFIRMATION =
  "VALIDATE_NEW_PASSWORD_CONFIRMATION";
export const CLEAR_VALIDATE_NEW_PASSWORD = "CLEAR_VALIDATE_NEW_PASSWORD";
export const CLEAR_VALIDATE_NEW_PASSWORD_CONFIRMATION =
  "CLEAR_VALIDATE_NEW_PASSWORD_CONFIRMATION";
export const FETCH_LOGOUT = "FETCH_LOGOUT";
export const LOGOUT_RESULT = "LOGOUT_RESULT";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT = "LOGOUT";
export const CLEAR_LOGOUT = "CLEAR_LOGOUT";
export const RESET_AND_SUBSCRIBE = "RESET_AND_SUBSCRIBE";

export const NAVIGATION = "Navigation/NAVIGATE";
export const CLEAR_NAVIGATE = "CLEAR_NAVIGATE";

export const SHARE = "SHARE";
export const CLEAR_SHARE = "CLEAR_SHARE";
export const SHARE_POST = "SHARE_POST";
export const CLEAR_SHARE_POST = "CLEAR_SHARE_POST";
export const CLEAR_SHARE_TEMPLATE_POST = "CLEAR_SHARE_TEMPLATE_POST";

export const FETCH_DELETE_USER = "FETCH_DELETE_USER";
export const DELETE_USER_RESULT = "DELETE_USER_RESULT";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const FETCH_PUSH_TOKEN = "FETCH_PUSH_TOKEN";
export const SET_TOKEN_RESULT = "SET_TOKEN_RESULT";

export const FETCH_CHECK_PSEUDO = "FETCH_CHECK_PSEUDO";
export const CHECK_PSEUDO_RESULT = "CHECK_PSEUDO_RESULT";
export const CHECK_PSEUDO_FAILURE = "CHECK_PSEUDO_FAILURE";

// LIVE
export const FETCH_GET_LIVE_EVENTS = "FETCH_GET_LIVE_EVENTS";
export const SET_LIVE_EVENTS = "SET_LIVE_EVENTS";
export const FETCH_DELETE_LIVE_EVENT = "FETCH_DELETE_LIVE_EVENT";
export const SET_DELETE_LIVE_EVENT_RESULT = "SET_DELETE_LIVE_EVENT_RESULT";
export const FETCH_INTERESTED_LIVE_EVENT = "FETCH_INTERESTED_LIVE_EVENT";
export const SET_INTERESTED_LIVE_EVENT_RESULT =
  "SET_INTERESTED_LIVE_EVENT_RESULT";
export const FETCH_UNINTERESTED_LIVE_EVENT = "FETCH_UNINTERESTED_LIVE_EVENT";
export const SET_UNINTERESTED_LIVE_EVENT_RESULT =
  "SET_UNINTERESTED_LIVE_EVENT_RESULT";
export const FETCH_GET_EVENT_INTERESTED_USERS =
  "FETCH_GET_EVENT_INTERESTED_USERS";
export const SET_EVENT_INTERESTED_USERS_RESULT =
  "SET_EVENT_INTERESTED_USERS_RESULT";
export const SET_EVENT_INTERESTED_USERS_FAILURE =
  "SET_EVENT_INTERESTED_USERS_FAILURE";
export const FETCH_FLAG_INAPPROPRIATE_EVENT_CONTENT =
  "FETCH_FLAG_INAPPROPRIATE_EVENT_CONTENT";
export const SET_FLAG_INAPPROPRIATE_EVENT_CONTENT_RESULT =
  "SET_FLAG_INAPPROPRIATE_EVENT_CONTENT_RESULT";
export const FETCH_PUBLISH_EVENT = "FETCH_PUBLISH_EVENT";
export const SET_PUBLISH_EVENT_RESULT = "SET_PUBLISH_EVENT_RESULT";
export const SET_PUBLISH_EVENT_FAILURE = "SET_PUBLISH_EVENT_FAILURE";
export const CLEAR_POST_PHOTO_DATA = "CLEAR_POST_PHOTO_DATA";
export const SET_POST_PHOTO_DATA = "SET_POST_PHOTO_DATA";
export const SET_POST_TITLE_FR = "SET_POST_TITLE_FR";
export const SET_POST_TITLE_EN = "SET_POST_TITLE_EN";
export const SET_POST_DESCRIPTION_FR = "SET_POST_DESCRIPTION_FR";
export const SET_POST_DESCRIPTION_EN = "SET_POST_DESCRIPTION_EN";
export const SET_POST_ENGLISH = "SET_POST_ENGLISH";
export const VALIDATE_POST_INPUTS = "VALIDATE_POST_INPUTS";
export const CLEAR_POST_VALIDATE = "CLEAR_POST_VALIDATE";
export const RESET_POST = "RESET_POST";

export const FETCH_GET_EVENT_DETAILS = "FETCH_GET_EVENT_DETAILS";
export const SET_GET_EVENT_DETAILS_RESULT = "SET_GET_EVENT_DETAILS_RESULT";
export const SET_GET_EVENT_DETAILS_FAILURE = "SET_GET_EVENT_DETAILS_FAILURE";

// METEO
export const FETCH_GET_FORECAST_METEO = "FETCH_GET_FORECAST_METEO";
export const SET_FORECAST_METEO = "SET_FORECAST_METEO";
export const FETCH_GET_CURRENT_METEO = "FETCH_GET_CURRENT_METEO";
export const SET_CURRENT_METEO = "SET_CURRENT_METEO";
export const FETCH_SHARE_METEO = "FETCH_SHARE_METEO";
export const SET_SHARE_METEO_RESULT = "SET_SHARE_METEO_RESULT";
export const SET_SHARE_METEO_FAILURE = "SET_SHARE_METEO_FAILURE";

// ADS
export const FETCH_GET_ADS = "FETCH_GET_ADS";
export const SET_ADS_RESULT = "SET_ADS_RESULT";
export const SET_ADS_FAILURE = "SET_ADS_FAILURE";
export const FETCH_GET_AD_DETAILS = "FETCH_GET_AD_DETAILS";
export const SET_AD_DETAILS_RESULT = "SET_AD_DETAILS_RESULT";
export const SET_AD_DETAILS_FAILURE = "SET_AD_DETAILS_FAILURE";
export const FETCH_GET_ADS_CATEGORIES = "FETCH_GET_ADS_CATEGORIES";
export const SET_ADS_CATEGORIES = "SET_ADS_CATEGORIES";
export const SET_ADS_CATEGORIES_FAILURE = "SET_ADS_CATEGORIES_FAILURE";
export const ADS_ON_TOP = "ADS_ON_TOP";
export const CATEGORIES_FILTER = "CATEGORIES_FILTER";
export const FETCH_GET_AD_LOCATION = "FETCH_GET_AD_LOCATION";
export const SET_AD_LOCATION_RESULT = "SET_AD_LOCATION_RESULT";
export const SET_AD_LOCATION_FAILURE = "SET_AD_LOCATION_FAILURE";
export const FETCH_UPDATE_AD_MAP_PHOTO_URI = "FETCH_UPDATE_AD_MAP_PHOTO_URI";
export const SET_UPDATE_AD_MAP_PHOTO_URI_RESULT =
  "SET_UPDATE_AD_MAP_PHOTO_URI_RESULT";

export const FETCH_PUBLISH_ACTIVITY_REQUEST = "FETCH_PUBLISH_ACTIVITY_REQUEST";
export const SET_PUBLISH_RESULT = "SET_PUBLISH_RESULT";
export const SET_PUBLISH_TEMPLATE = "SET_PUBLISH_TEMPLATE";
export const SET_PUBLISH_GOODDEAL_TEMPLATE = "SET_PUBLISH_GOODDEAL_TEMPLATE";
export const SET_PUBLISH_DATE = "SET_PUBLISH_DATE";
export const SET_PUBLISH_TIME = "SET_PUBLISH_TIME";
export const SET_PUBLISH_EQUIPMENT = "SET_PUBLISH_EQUIPMENT";
export const SET_PUBLISH_CHOICE = "SET_PUBLISH_CHOICE";
export const CLEAR_PUBLISH = "CLEAR_PUBLISH";

// CHAT
export const FETCH_GET_CHAT_MESSAGES = "FETCH_GET_CHAT_MESSAGES";
export const SET_CHAT_MESSAGES = "SET_CHAT_MESSAGES";
export const FETCH_GET_MY_CHAT_USERS = "FETCH_GET_MY_CHAT_USERS";
export const SET_MY_CHAT_USERS = "SET_MY_CHAT_USERS";
export const SET_MY_CHAT_USERS_FAILURE = "SET_MY_CHAT_USERS_FAILURE";
export const FETCH_GET_CHAT_NEIGHBORS = "FETCH_GET_CHAT_NEIGHBORS";
export const SET_CHAT_NEIGHBORS = "SET_CHAT_NEIGHBORS";
export const SET_CHAT_NEIGHBORS_FAILURE = "SET_CHAT_NEIGHBORS_FAILURE";
export const FETCH_GET_CHAT_EMPLOYEES = "FETCH_GET_CHAT_EMPLOYEES";
export const SET_CHAT_EMPLOYEES = "SET_CHAT_EMPLOYEES";
export const SET_CHAT_EMPLOYEES_FAILURE = "SET_CHAT_EMPLOYEES_FAILURE";
export const FETCH_EXIT_CHAT = "FETCH_EXIT_CHAT";
export const SET_CHAT_EXIT_RESULT = "SET_CHAT_EXIT_RESULT";
export const SET_IN_CHAT_WITH = "SET_IN_CHAT_WITH";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const FETCH_GET_CHAT_EVENTS = "FETCH_GET_CHAT_EVENTS";
export const SET_CHAT_EVENTS_RESULT = "SET_CHAT_EVENTS_RESULT";
export const SET_CHAT_PHOTO_DATA = "SET_CHAT_PHOTO_DATA";
export const CLEAR_CHAT_PHOTO_DATA = "CLEAR_CHAT_PHOTO_DATA";
export const CLEAR_CHAT_MAP_DATA = "CLEAR_CHAT_MAP_DATA";
export const SET_CHAT_CURRENT_LOCATION = "SET_CHAT_CURRENT_LOCATION";
export const SET_READY_TO_COPY_URL = "SET_READY_TO_COPY_URL";
export const CLEAR_READY_TO_COPY_URL = "CLEAR_READY_TO_COPY_URL";
export const SET_CHAT_VIDEO_ID = "SET_CHAT_VIDEO_ID";
export const CLEAR_CHAT_VIDEO_ID = "CLEAR_CHAT_VIDEO_ID";
export const SET_POST_VIDEO_ID = "SET_POST_VIDEO_ID";

// TEMPLATES
export const FETCH_GET_TEMPLATES_CATEGORIES = "FETCH_GET_TEMPLATES_CATEGORIES";
export const FETCH_GET_OTHER_TEMPLATES_CATEGORIES =
  "FETCH_GET_OTHER_TEMPLATES_CATEGORIES";
export const FETCH_GET_TEMPLATES_BY_CATEGORY =
  "FETCH_GET_TEMPLATES_BY_CATEGORY";
export const SET_TEMPLATE_BY_CATEGORY = "SET_TEMPLATE_BY_CATEGORY";
export const SET_TEMPLATE_CATEGORIES = "SET_TEMPLATE_CATEGORIES";
export const FETCH_GET_TEMPLATES = "FETCH_GET_TEMPLATES";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const SET_TEMPLATES_FAILURE = "SET_TEMPLATES_FAILURE";
export const FETCH_GET_TEMPLATE_DATES = "FETCH_GET_TEMPLATE_DATES";
export const SET_TEMPLATE_DATES = "SET_TEMPLATE_DATES";
export const SET_TEMPLATE_DATES_FAILURE = "SET_TEMPLATE_DATES_FAILURE";
export const FETCH_GET_TEMPLATE_TIMES = "FETCH_GET_TEMPLATE_TIMES";
export const SET_TEMPLATE_TIMES = "SET_TEMPLATE_TIMES";
export const SET_TEMPLATE_TIMES_FAILURE = "SET_TEMPLATE_TIMES_FAILURE";
export const FETCH_GET_GOOD_DEALS_TEMPLATES = "FETCH_GET_GOOD_DEALS_TEMPLATES";
export const SET_GOOD_DEALS_TEMPLATES = "SET_GOOD_DEALS_TEMPLATES";

// LOCATIONS
export const FETCH_GET_LOCATIONS = "FETCH_GET_LOCATIONS";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_LOCATIONS_FAILURE = "SET_LOCATIONS_FAILURE";

// SETTINGS
export const FETCH_GET_NOTIF_USER_TEMPLATES = "FETCH_GET_NOTIF_USER_TEMPLATES";
export const FETCH_SET_NOTIF_USER_TEMPLATES = "FETCH_SET_NOTIF_USER_TEMPLATES";
export const FETCH_DELETE_NOTIF_USER_TEMPLATE =
  "FETCH_DELETE_NOTIF_USER_TEMPLATE";
export const SET_NOTIF_USER_TEMPLATES = "SET_NOTIF_USER_TEMPLATES";
export const SET_NOTIF_USER_TEMPLATES_FAILURE =
  "SET_NOTIF_USER_TEMPLATES_FAILURE";
export const FETCH_GET_NOTIF_STATUS = "FETCH_GET_NOTIF_STATUS";
export const SET_NOTIF_STATUS = "SET_NOTIF_STATUS";
export const SET_NOTIF_STATUS_FAILURE = "SET_NOTIF_STATUS_FAILURE";
export const FETCH_SET_NOTIF_ALL = "FETCH_SET_NOTIF_ALL";
export const FETCH_SET_NOTIF_SELECTION = "FETCH_SET_NOTIF_SELECTION";

export const STATUS_UNDEFINED = "STATUS_UNDEFINED";
export const STATUS_ALL = "STATUS_ALL";
export const STATUS_SELECTION = "STATUS_SELECTION";

export const SET_LANG = "SET_LANG";
export const SET_INSTALLATION_ID = "SET_INSTALLATION_ID";

// Error management
export const SET_DROP_DOWN_ALERT_ERROR = "SET_DROP_DOWN_ALERT_ERROR";
export const SET_DROP_DOWN_ALERT_WARN = "SET_DROP_DOWN_ALERT_WARN";
export const SET_DROP_DOWN_ALERT_INFO = "SET_DROP_DOWN_ALERT_INFO";
export const CLEAR_DROP_DOWN_ALERT_MESSAGE = "CLEAR_DROP_DOWN_ALERT_MESSAGE";
export const CLEAR_DROP_DOWN_ALERT_ERROR = "CLEAR_DROP_DOWN_ALERT_ERROR";
export const CLEAR_DROP_DOWN_ALERT_WARN = "CLEAR_DROP_DOWN_ALERT_WARN";
export const CLEAR_DROP_DOWN_ALERT_INFO = "CLEAR_DROP_DOWN_ALERT_INFO";

export const SET_HAS_CAMERA_PERMISSION = "SET_HAS_CAMERA_PERMISSION";
export const SET_SCANNED_DATA = "SET_SCANNED_DATA";
export const CLEAR_SCAN = "CLEAR_SCAN";
export const SHOW_QR_CODE_SCANNER_MODAL = "SHOW_QR_CODE_SCANNER_MODAL";
export const HIDE_QR_CODE_SCANNER_MODAL = "HIDE_QR_CODE_SCANNER_MODAL";

export const FETCH_GET_GLOBAL_INFORMATIONS = "FETCH_GET_GLOBAL_INFORMATIONS";
export const FETCH_GET_GLOBAL_INFORMATIONS_FROM_LOGIN =
  "FETCH_GET_GLOBAL_INFORMATIONS_FROM_LOGIN";
export const FETCH_GET_GLOBAL_INFORMATIONS_VERSION =
  "FETCH_GET_GLOBAL_INFORMATIONS_VERSION";
export const SET_GLOBAL_INFORMATIONS = "SET_GLOBAL_INFORMATIONS";
export const SET_GLOBAL_INFORMATIONS_VERSIONS =
  "SET_GLOBAL_INFORMATIONS_VERSIONS";
export const SET_GLOBAL_INFORMATIONS_FAILURE =
  "SET_GLOBAL_INFORMATIONS_FAILURE";
export const SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE =
  "SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE";

export const FETCH_GET_ALL_DATA = "FETCH_GET_ALL_DATA";
export const RESET_DATA = "RESET_DATA";

export const LIVE_ON_TOP = "LIVE_ON_TOP";
export const SET_FILTER = "SET_FILTER";
export const DISPLAY_ALL = "all";
export const DISPLAY_ONLY_COMMUNITY_POSTS = "community";
export const DISPLAY_ONLY_MY_POSTS = "my";
export const LIVE_REFRESH = "LIVE_REFRESH";

export const SET_ON_CLICK_CHAT_MENU = "SET_ON_CLICK_CHAT_MENU";
export const TOGGLE_DISPLAY_CHAT_MENU = "TOGGLE_DISPLAY_CHAT_MENU";
export const TOGGLE_DISPLAY_PREVIEW = "TOGGLE_DISPLAY_PREVIEW";
export const SET_DISPLAY_FILTER_OPTION = "SET_DISPLAY_FILTER_OPTION";
export const SET_GOODDEAL_DISPLAY_FILTER_OPTION =
  "SET_GOODDEAL_DISPLAY_FILTER_OPTION";

export const SET_MAP_PHOTO_URI = "SET_MAP_PHOTO_URI";
