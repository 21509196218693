import {
  API_START,
  API_END,
  RESET_DATA,
  SET_CITIES,
  SET_UPDATE_COMMUNITY_MAP_PHOTO_URI_RESULT,
  FETCH_SEARCH_COMMUNITIES,
  SET_SEARCH_COMMUNITIES_RESULT,
  SET_NEAR_COMMUNITIES,
  CLEAR_COMMUNITIES,
  SET_REGION_COORDINATES,
  FETCH_GET_CITIES,
  SET_COMMUNITY_DETAILS,
  FETCH_GET_COMMUNITY_DETAILS,
  SET_COMMUNITY_DETAILS_FAILURE
} from "../actions/types";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { merge } from "lodash";

const THRESHOLD_PERCENTAGE = 80;

const initialState = {
  isFetching: false,
  isFetchingList: false,
  communities: {},
  communitiesHasNextPage: false,
  communitiesNextPage: 2,
  cities: {},
  currentCommunity: null,
  page: 1,
  hasNextPage: false,
  nextPage: 2,
  threshold: 0,
  coordinates: [],
  isNotFound: false
};

export default function communityReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START:
      if (action.payload === FETCH_GET_COMMUNITY_DETAILS) {
        return {
          ...state,
          isNotFound: false,
          isFetching: true
        };
      } else if (
        action.payload === FETCH_SEARCH_COMMUNITIES ||
        action.payload === FETCH_GET_CITIES
      ) {
        return {
          ...state,
          isFetchingList: true
        };
      }
      break;

    case RESET_DATA:
      return {
        ...initialState,
        currentCommunity: state.currentCommunity
      };

    case SET_SEARCH_COMMUNITIES_RESULT: {
      const { communities, infospages } = action.entities;

      const { page, hasNextPage: communitiesHasNextPage } = infospages[1];
      if (page === 1) {
        return {
          ...state,
          communities: communities || {},
          communitiesHasNextPage,
          communitiesNextPage: page + 1
        };
      } else {
        return {
          ...state,
          communitiesHasNextPage,
          communitiesNextPage: page + 1,
          communities: merge({}, state.communities, communities)
        };
      }
    }

    case CLEAR_COMMUNITIES:
      return {
        ...state,
        threshold: 0,
        page: 1,
        nextPage: 2,
        hasNextPage: false,
        communities: {},
        coordinates: []
      };

    case SET_REGION_COORDINATES:
      return {
        ...state,
        coordinates: action.payload
      };

    case SET_NEAR_COMMUNITIES:
      const { communities, infospages } = action.entities;
      return {
        ...state,
        threshold: (
          (THRESHOLD_PERCENTAGE * infospages[1].maxDistance) /
          100
        ).toFixed(2),
        communities: merge({}, state.communities, communities)
      };

    case SET_UPDATE_COMMUNITY_MAP_PHOTO_URI_RESULT:
      return {
        ...state,
        currentCommunity: {
          ...state.currentCommunity,
          mapPhotoUri: action.payload.mapPhotoUri
        }
      };

    case SET_COMMUNITY_DETAILS:
      if (action.entities.communities) {
        const currentCommunity = Object.values(action.entities.communities)[0];
        if (currentCommunity) {
          AsyncStorage.setItem(
            "neighborhoodUri",
            currentCommunity.customUri
              ? currentCommunity.customUri
              : currentCommunity._id
          );
          return {
            ...state,
            currentCommunity
          };
        }
      }
      break;

    case SET_COMMUNITY_DETAILS_FAILURE: {
      const errorMsg = action.payload?.message || "";
      if (errorMsg.endsWith("404")) {
        AsyncStorage.removeItem("communityUri");
        return {
          ...state,
          isNotFound: true
        };
      }
      break;
    }

    case SET_CITIES: {
      const { cities, infospages } = action.entities;
      const { page, hasNextPage } = infospages["1"];
      if (page === 1) {
        return {
          ...state,
          cities: cities ? cities : {},
          page,
          nextPage: 1 + page,
          hasNextPage
        };
      } else {
        return merge({}, state, {
          cities,
          page,
          nextPage: 1 + page,
          hasNextPage
        });
      }
    }

    case API_END:
      if (action.payload === FETCH_GET_COMMUNITY_DETAILS) {
        return {
          ...state,
          isFetching: false
        };
      } else if (action.payload === FETCH_SEARCH_COMMUNITIES) {
        return {
          ...state,
          isFetchingList: false
        };
      }
      break;

    default:
      return state;
  }
  return state;
}
