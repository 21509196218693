import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { Camera } from "expo-camera";
import * as ScreenOrientation from "expo-screen-orientation";
import React, { useState, useEffect } from "react";
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  StatusBar
} from "react-native";
import SafeAreaView from "react-native-safe-area-view";
import { t } from "../services/i18n";
import commonStyles, {
  SCREEN_WIDTH,
  SCREEN_HEIGHT,
  DARKGREY_COLOR,
  COLOR2,
  COLOR5,
  isWeb,
  isNative
} from "../styles/commonStyles";
import { Slider } from "react-native-elements";
import ActionButton from "../components/actionButton/ActionButton";
import { useNavigation } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import {
  SET_CHAT_PHOTO_DATA,
  SET_DROP_DOWN_ALERT_INFO,
  SET_PHOTO_URI,
  SET_POST_PHOTO_DATA
} from "../actions/types";

const gridSize =
  SCREEN_WIDTH > SCREEN_HEIGHT ? SCREEN_HEIGHT - 300 : SCREEN_WIDTH - 20;

const screenParams = {
  chat: {
    showGridParam: false,
    type: SET_CHAT_PHOTO_DATA,
    showZoom: true,
    cameraTypeParam: Camera.Constants.Type.back
  },
  post: {
    type: SET_POST_PHOTO_DATA,
    showZoom: true
  },
  profile: {
    showGridParam: true,
    type: SET_PHOTO_URI,
    cameraTypeParam: Camera.Constants.Type.front
  }
};

export default function CameraScreen(props) {
  const {
    justifyContentCenter,
    alignItemsCenter,
    bgColor1,
    bgColor2,
    flex1,
    p10,
    w100p,
    bgWhite,
    opacity05,
    opacity1,
    fontBold,
    fs16,
    fs18,
    red,
    mb20
  } = commonStyles;

  let camera;

  const {
    cameraTypeParam,
    type,
    showGridParam,
    showZoom = false
  } = screenParams[props.route?.params.from || "profile"];

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [hasPermission, setHasPermission] = useState(null);
  const [showGrid, setShowGrid] = useState(true);
  const [showSwitchCamera, setShowSwitchCamera] = useState(true);
  const [bestRatio, setBestRatio] = useState("");
  const [flashMode, setFlashMode] = useState(Camera.Constants.FlashMode.off);
  const [isReady, setReady] = useState(null);
  const [isCapturing, setCaturing] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [cameraType, setCameraType] = useState(Camera.Constants.Type.back);
  const [zoom, setZoom] = useState(0);

  useEffect(() => {
    if (isReady) {
      (async () => {
        const { status } = await Camera.requestPermissionsAsync();
        if (status !== "granted") {
          dispatch({
            type: SET_DROP_DOWN_ALERT_INFO,
            info: "camerainfo"
          });
        }
        setHasPermission(status === "granted");
      })();
    } else {
      setHasPermission(false);
    }
  }, [isReady]);

  useEffect(() => {
    if (isCapturing) {
      (async () => {
        await capture();
      })();
    }
  }, [isCapturing]);

  useEffect(() => {
    (async () => {
      if (
        Platform.OS === "android" &&
        cameraTypeParam === Camera.Constants.Type.back
      ) {
        if (
          await ScreenOrientation.supportsOrientationLockAsync(
            ScreenOrientation.OrientationLock.ALL
          )
        ) {
          await ScreenOrientation.unlockAsync(
            ScreenOrientation.OrientationLock.ALL
          );
        }
      }
    })();

    return async () => {
      if (
        Platform.OS === "android" &&
        cameraTypeParam === Camera.Constants.Type.back
      ) {
        if (
          await ScreenOrientation.supportsOrientationLockAsync(
            ScreenOrientation.OrientationLock.PORTRAIT
          )
        ) {
          await ScreenOrientation.lockAsync(
            ScreenOrientation.OrientationLock.PORTRAIT
          );
        }
      }
    };
  }, []);

  const onCameraReady = async () => {
    // compute best ratio for android
    if (Platform.OS === "android" && !bestRatio) {
      const wantedRatio = SCREEN_HEIGHT / SCREEN_WIDTH;
      const ratios = await camera.getSupportedRatiosAsync();
      let bestRatio = 0;
      let bestRatioError = 100000;
      for (const ratio of ratios) {
        const r = ratio.split(":");
        if (Math.abs(wantedRatio - r[0] / r[1]) < bestRatioError) {
          bestRatioError = Math.abs(wantedRatio - r[0] / r[1]);
          bestRatio = ratio;
        }
      }
      setBestRatio(bestRatio);
    }
  };

  const capture = async () => {
    let photoData = await camera.takePictureAsync({
      quality: 0.5,
      base64: true
    });

    const { base64, uri } = photoData;

    const files = [];
    if (isWeb) {
      files.push({
        name: "" + Date.now(),
        base64: uri
      });
    } else {
      const name = uri.substring(
        uri.lastIndexOf("/") + 1,
        uri.lastIndexOf(".")
      );
      files.push({
        name,
        base64: "data:image/jpeg;base64," + base64
      });
    }

    dispatch({
      type,
      value: uri,
      files
    });
    navigation.goBack();
  };

  if (!isReady) {
    (async () => {
      if (isWeb) {
        const ready = await Camera.isAvailableAsync();
        if (ready) {
          const types = await Camera.getAvailableCameraTypesAsync();
          // types are empty on iphone
          // if (types.length === 0) {
          //   setHasPermission(false);
          //   setShowSwitchCamera(false);
          // } else
          if (types.length === 1) {
            switch (types[0]) {
              case Camera.Constants.Type.front:
                // Workaround: strange on macos, if front camera type should be set to back
                setCameraType(Camera.Constants.Type.back);
                setShowSwitchCamera(false);
                setShowGrid(showGridParam);
                setReady(true);
                break;
              case Camera.Constants.Type.back:
                setCameraType(Camera.Constants.Type.front);
                setShowSwitchCamera(false);
                setReady(true);
                break;
            }
          } else {
            setCameraType(cameraTypeParam);
            setShowGrid(showGridParam);
            setReady(true);
          }
        } else {
          setHasPermission(false);
          setReady(false);
        }
      } else {
        setCameraType(cameraTypeParam);
        setShowGrid(showGridParam);
        setReady(true);
      }
    })();
  }

  if (hasPermission === null) {
    return <View />;
  } else if (!hasPermission) {
    return (
      <View style={[flex1, bgColor1]}>
        <View style={[flex1, alignItemsCenter, justifyContentCenter]}>
          <Text style={[fontBold, red, fs18]}>
            {t("profile:nocameraaccess")}
          </Text>
        </View>
      </View>
    );
  } else {
    return (
      <SafeAreaView
        style={[
          flex1,
          isCapturing && bgWhite,
          isCapturing ? opacity05 : opacity1
        ]}
      >
        <StatusBar barStyle="dark-content" />
        {isCapturing && (
          <View style={styles.loading_container}>
            <ActivityIndicator size="large" color={COLOR2} />
          </View>
        )}
        <Camera
          ref={cameraRef => (camera = cameraRef)}
          style={[flex1]}
          type={cameraType}
          autoFocus={Camera.Constants.AutoFocus.on}
          flashMode={flashMode}
          onCameraReady={async () => {
            await onCameraReady();
          }}
          zoom={zoom}
        >
          {showGrid && (
            <View style={[flex1, alignItemsCenter, justifyContentCenter]}>
              <View style={styles.grid}>
                <View style={styles.row}>
                  <View style={styles.cell} />
                  <View style={styles.cell} />
                </View>
                <View style={styles.row}>
                  <View style={styles.cell} />
                  <View style={styles.cell} />
                </View>
                <View style={styles.row}>
                  <View style={styles.cell} />
                  <View style={styles.cell} />
                </View>
              </View>
            </View>
          )}

          <SafeAreaView
            style={[
              flex1,
              { position: "absolute", bottom: 20 },
              w100p,
              alignItemsCenter
            ]}
          >
            {showZoom && (
              <View style={[w100p, mb20]}>
                <Slider
                  style={[{ marginHorizontal: 30 }]}
                  value={zoom}
                  thumbTintColor={COLOR2}
                  onValueChange={zoom => setZoom(zoom)}
                />
              </View>
            )}
            <TouchableOpacity
              disabled={isCapturing}
              onPress={() => setCaturing(true)}
              style={[
                {
                  width: 64,
                  height: 64,
                  borderRadius: 32,
                  borderWidth: 4,
                  borderColor: "white"
                },
                justifyContentCenter,
                alignItemsCenter,
                p10
              ]}
            >
              <View
                style={[
                  { width: 52, height: 52, borderRadius: 26 },
                  justifyContentCenter,
                  alignItemsCenter,
                  bgColor2
                ]}
              >
                <Ionicons name="ios-camera" color="white" size={30} />
              </View>
            </TouchableOpacity>
          </SafeAreaView>
        </Camera>
        <ActionButton
          buttonColor={isMenuOpen ? COLOR5 : COLOR2}
          degrees={90}
          renderIcon={() => (
            <MaterialCommunityIcons
              name="dots-horizontal"
              size={40}
              color="white"
            />
          )}
          onPressIn={() => setMenuOpen(true)}
          onReset={() => setMenuOpen(false)}
          // disabled={isFetching}
        >
          <ActionButton.Item
            buttonColor={"red"}
            title={t("button:cancel")}
            onPress={() => navigation.goBack()}
            textStyle={[fontBold, fs16]}
          >
            <MaterialCommunityIcons name="cancel" size={36} color="white" />
          </ActionButton.Item>
          {isNative && cameraType === Camera.Constants.Type.back && (
            <ActionButton.Item
              buttonColor={COLOR2}
              title={
                flashMode === Camera.Constants.FlashMode.on
                  ? t("button:flashmodeoff")
                  : t("button:flashmodeon")
              }
              onPress={() =>
                setFlashMode(
                  flashMode === Camera.Constants.FlashMode.on
                    ? Camera.Constants.FlashMode.off
                    : Camera.Constants.FlashMode.on
                )
              }
              textStyle={[fontBold, fs16]}
              disabled={!isReady}
            >
              <Ionicons
                name={
                  flashMode === Camera.Constants.FlashMode.on
                    ? "ios-flash-off"
                    : "ios-flash"
                }
                size={36}
                color="white"
              />
            </ActionButton.Item>
          )}
          <ActionButton.Item
            buttonColor={COLOR2}
            title={showGrid ? t("button:hidegrid") : t("button:displaygrid")}
            onPress={() => setShowGrid(!showGrid)}
            textStyle={[fontBold, fs16]}
            disabled={!isReady}
          >
            <Ionicons name="ios-grid" size={36} color="white" />
          </ActionButton.Item>
          {showSwitchCamera && (
            <ActionButton.Item
              buttonColor={COLOR2}
              title={
                cameraType === Camera.Constants.Type.front
                  ? t("button:backcamera")
                  : t("button:frontcamera")
              }
              onPress={() =>
                setCameraType(
                  cameraType === Camera.Constants.Type.front
                    ? Camera.Constants.Type.back
                    : Camera.Constants.Type.front
                )
              }
              textStyle={[{ minHeight: 50 }, fontBold, fs16]}
              disabled={!isReady}
            >
              <Ionicons name="camera-reverse" size={36} color="white" />
            </ActionButton.Item>
          )}
        </ActionButton>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  loading_container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 100,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center"
  },
  grid: {
    borderColor: DARKGREY_COLOR,
    borderWidth: 1,
    borderBottomWidth: Platform.OS === "android" ? 1 : 0,
    width: gridSize,
    height: gridSize
  },
  row: {
    borderColor: DARKGREY_COLOR,
    borderBottomWidth: 1,
    height: gridSize / 3,
    flexDirection: "row"
  },
  cell: {
    borderColor: DARKGREY_COLOR,
    borderRightWidth: 1,
    height: gridSize / 3,
    width: gridSize / 3
  }
});
