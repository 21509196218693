import {
  FETCH_FAILURE,
  FETCH_UPDATE_COMMUNITY_MAP_PHOTO_URI,
  SET_UPDATE_COMMUNITY_MAP_PHOTO_URI_RESULT,
  FETCH_SEARCH_COMMUNITIES,
  SET_SEARCH_COMMUNITIES_RESULT,
  FETCH_GET_COMMUNITY_DETAILS,
  FETCH_GET_NEAR_COMMUNITIES,
  SET_NEAR_COMMUNITIES,
  SET_COMMUNITY_DETAILS,
  SET_COMMUNITY_DETAILS_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";
import { COMMUNITIES, COMMUNITY } from "../config/constants";

const equipment = new schema.Entity(
  "equipments",
  {},
  {
    idAttribute: "_id"
  }
);

const community = new schema.Entity(
  "communities",
  {
    equipmentsIds: [equipment]
  },
  {
    idAttribute: "_id"
  }
);

const infospages = new schema.Entity("infospages", { docs: [community] });

export function fetchSearchCummunities({ page, longitude, latitude, search }) {
  return apiAction({
    url: `/${COMMUNITIES}/search`,
    method: "GET",
    schema: infospages,
    data: {
      page,
      longitude,
      latitude,
      name: search
    },
    onSuccess: setSearchCommunitiesResult,
    label: FETCH_SEARCH_COMMUNITIES
  });
}

function setSearchCommunitiesResult(normalized) {
  return {
    type: SET_SEARCH_COMMUNITIES_RESULT,
    entities: normalized.entities
  };
}

export function fetchGetCommunityDetails(communityUri) {
  return apiAction({
    url: `/${COMMUNITIES}/${COMMUNITY}`,
    method: "GET",
    schema: community,
    data: communityUri && {
      communityUri
    },
    onSuccess: setCommunityDetails,
    onFailure: setCommunityDetailsFailure,
    label: FETCH_GET_COMMUNITY_DETAILS
  });
}

function setCommunityDetails(normalized) {
  return {
    type: SET_COMMUNITY_DETAILS,
    entities: normalized.entities
  };
}

function setCommunityDetailsFailure(error) {
  return {
    type: SET_COMMUNITY_DETAILS_FAILURE,
    payload: error
  };
}

export function fetchGetNearCommunities(longitude, latitude) {
  return apiAction({
    url: `/${COMMUNITIES}/near`,
    method: "GET",
    schema: infospages,
    data: {
      longitude,
      latitude,
      limit: 5
    },
    onSuccess: setNearCommunities,
    label: FETCH_GET_NEAR_COMMUNITIES
  });
}

function setNearCommunities(normalized) {
  return {
    type: SET_NEAR_COMMUNITIES,
    entities: normalized.entities
  };
}

export function fetchUpdateCommunityMapPhotoUri(data) {
  return apiAction({
    url: "/communities/map",
    method: "PUT",
    data,
    onSuccess: setUpdateCommunityMapPhotoUriResult,
    onFailure: setFetchFailure,
    label: FETCH_UPDATE_COMMUNITY_MAP_PHOTO_URI
  });
}

function setUpdateCommunityMapPhotoUriResult(data) {
  return {
    type: SET_UPDATE_COMMUNITY_MAP_PHOTO_URI_RESULT,
    payload: data
  };
}

function setFetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    payload: error
  };
}
