import { NavigationContainer } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import DropdownAlert from "react-native-dropdownalert";
import { ThemeProvider } from "react-native-elements";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  LOGOUT,
  NAVIGATION,
  ON_APP_BLUR,
  ON_APP_FOCUS,
  RESET_AND_SUBSCRIBE
} from "../actions/types";
import {
  ABOUT_ROUTE,
  AD_ROUTE,
  APPLE_STORE_URL,
  CAMERA_ROUTE,
  CGU_ROUTE,
  CITIES_ROUTE,
  COMMUNITIES_MAPS_ROUTE,
  COMMUNITIES_ROUTE,
  DEEPLINK_APP_URL,
  DEEPLINK_URL_SCHEME,
  GOOGLE_PLAY_URL,
  LOCAL_LIFE_ROUTE,
  MAINTENANCE_ROUTE,
  MY_CONVERSATIONS_ROUTE,
  MY_PROFILE_ROUTE,
  EVENT_DETAILS_ROUTE,
  PROFILE_ROUTE,
  PWA_DEEPLINK_APP_URL,
  REGISTER_ROUTE,
  ROLE_GUEST,
  URL_PREFIX,
  YOUTUBE_ROUTE,
  ZOOM_IMAGE_ROUTE
} from "../config/constants";
import env from "../config/env";
import { getAppNavigationSelector } from "../selectors";
// Services
import DeepLinkManagement from "../services/DeepLinkManagement";
import ErrorManagement from "../services/ErrorManagement";
import i18n from "../services/i18n";
import PushNotifications from "../services/PushNotifications";
import { COLOR2, GREEN_COLOR, isElectron, isWeb } from "../styles/commonStyles";
import isDevelopment from "../utils/electron-is-dev";
import { openURL } from "../utils/UrlUtil";
import { createRootAppStackNavigator } from "./AppNavigator";

const DEEPLINK_DEV_URL = env.deepLinkUrl || "exp://localhost:19000/--/";

const theme = {
  colors: {
    primary: COLOR2
  }
};

const isDevElectron = isElectron && isDevelopment;

// Gets the current screen from navigation state
const getActiveRouteName = state => {
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route.name;
};

const getActiveRoute = state => {
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route;
};

export default function AppNavigationContainer() {
  const dispatch = useDispatch();

  const [isReady, setIsReady] = useState(false);

  const {
    user,
    accessDenied,
    lang,
    displayPreview,
    communityDetails,
    isFetching
  } = useSelector(getAppNavigationSelector, shallowEqual);

  const routeNameRef = useRef();
  const navigationRef = useRef();
  const dropdownAlertRef = useRef();

  const navigation = navigationRef?.current;

  const onFocusFunction = () => {
    dispatch({ type: ON_APP_FOCUS });
  };

  const onBlurFunction = () => {
    dispatch({ type: ON_APP_BLUR });
  };

  useEffect(() => {
    if (isWeb) {
      window.addEventListener("focus", onFocusFunction);
      window.addEventListener("blur", onBlurFunction);
    }
    return () => {
      if (isWeb) {
        window.removeEventListener("focus", onFocusFunction);
        window.removeEventListener("blur", onBlurFunction);
      }
    };
  }, []);

  useEffect(() => {
    if (lang) {
      (async () => {
        await i18n.setLanguage(lang);
      })();
    }
  }, [lang]);

  const onTap = message => {
    if (message.payload) {
      if (user && user !== ROLE_GUEST && message.payload.id) {
        navigation?.navigate("ChatTeamScreen", {
          to: message.payload.id
        });
      } else if (user && user !== ROLE_GUEST && message.payload.pseudo) {
        navigation?.navigate("ChatScreen", {
          pseudo: message.payload.pseudo
        });
      } else if (user && user !== ROLE_GUEST && message.payload.eventId) {
        navigation?.navigate("ChatGrpScreen", {
          eventId: message.payload.eventId
        });
      } else if (message.payload.dropDownAlertWarn === "obsoleteversionios") {
        openURL(dispatch, APPLE_STORE_URL);
      } else if (
        message.payload.dropDownAlertWarn === "obsoleteversionandroid"
      ) {
        openURL(dispatch, GOOGLE_PLAY_URL);
      } else if (
        message.payload.dropDownAutoClearAlertWarn === "guestforbidden"
      ) {
        dispatch({
          type: LOGOUT,
          value: RESET_AND_SUBSCRIBE
        });
      }
    }
  };

  const onClose = message => {
    if (message.payload.dropDownAutoClearAlertWarn === "needupdate") {
      Updates.reloadAsync();
    }
  };

  useEffect(() => {
    if (isReady && navigation) {
      const state = navigation.getRootState();

      // Save the initial route name
      routeNameRef.current = getActiveRouteName(state);
    }
  }, [isReady]);

  const prefix = URL_PREFIX ? URL_PREFIX + "/" : "";

  const prefixes = __DEV__
    ? [
        PWA_DEEPLINK_APP_URL,
        DEEPLINK_APP_URL,
        DEEPLINK_URL_SCHEME,
        DEEPLINK_DEV_URL
      ]
    : [PWA_DEEPLINK_APP_URL, DEEPLINK_APP_URL, DEEPLINK_URL_SCHEME];

  const loading = {
    prefixes,
    config: {
      screens: {
        LoadingScreen: isDevElectron ? "" : `${URL_PREFIX}`,
        MaintenanceScreen: isDevElectron ? "" : `${prefix}${MAINTENANCE_ROUTE}`
      }
    }
  };

  const landing = {
    prefixes,
    config: {
      screens: {
        CitiesModal: isDevElectron ? "" : `${URL_PREFIX}${CITIES_ROUTE}`,
        CommunitiesModal: isDevElectron
          ? ""
          : `${URL_PREFIX}${COMMUNITIES_ROUTE}`,
        CommunitiesMapModal: isDevElectron
          ? ""
          : `${URL_PREFIX}${COMMUNITIES_MAPS_ROUTE}`,
        AppInfosScreen: isDevElectron ? "" : `${prefix}${ABOUT_ROUTE}`,
        CGUScreen: isDevElectron ? "" : `${prefix}${CGU_ROUTE}`,
        AdDetailsScreen: isDevElectron ? "" : `${prefix}${AD_ROUTE}`,
        AdScreen: isDevElectron
          ? ""
          : `${prefix}${LOCAL_LIFE_ROUTE}/:cityUri/:adUri`,
        // SelectTemplateDateScreen: isDevElectron
        //   ? ""
        //   : `${prefix}parametres-publication`,
        EventDetailsScreen: isDevElectron
          ? ""
          : `${prefix}${EVENT_DETAILS_ROUTE}`,
        ImageZoomScreen: isDevElectron ? "" : `${prefix}${ZOOM_IMAGE_ROUTE}`,
        MaintenanceScreen: isDevElectron ? "" : `${prefix}${MAINTENANCE_ROUTE}`,
        LandingScreen: isDevElectron ? "" : `${URL_PREFIX}:communityUri`,
        LoginModal: isDevElectron ? "" : `${URL_PREFIX}:communityUri/connexion`,
        CommunityDetailsScreen: isDevElectron
          ? ""
          : `${prefix}:communityUri/informations`
      }
    }
  };

  const registerUser = {
    prefixes,
    config: {
      screens: {
        ProfileUpdateScreen: isDevElectron
          ? ""
          : `${prefix}:communityUri/${REGISTER_ROUTE}`
      }
    }
  };

  const authenticated = {
    prefixes: [
      PWA_DEEPLINK_APP_URL,
      DEEPLINK_APP_URL,
      DEEPLINK_URL_SCHEME
      // DEEPLINK_DEV_URL
    ],
    config: {
      screens: {
        ProfileUpdateScreen: isDevElectron
          ? ""
          : `${prefix}${MY_PROFILE_ROUTE}`,
        CameraScreen: isDevElectron ? "" : `${prefix}${CAMERA_ROUTE}`,
        ProfileScreen: isDevElectron ? "" : `${prefix}${PROFILE_ROUTE}/:pseudo`,
        ProfileTeamScreen: isDevElectron ? "" : `${prefix}${PROFILE_ROUTE}`,
        ImageZoomScreen: isDevElectron ? "" : `${prefix}${ZOOM_IMAGE_ROUTE}`,
        YoutubeScreen: isDevElectron ? "" : `${prefix}${YOUTUBE_ROUTE}`,
        CGUScreen: isDevElectron ? "" : `${prefix}${CGU_ROUTE}`,
        MaintenanceScreen: isDevElectron ? "" : `${prefix}${MAINTENANCE_ROUTE}`,
        ChatTeamScreen: isDevElectron
          ? ""
          : `${prefix}${MY_CONVERSATIONS_ROUTE}/conversation`,
        ChatScreen: isDevElectron
          ? ""
          : `${prefix}${MY_CONVERSATIONS_ROUTE}/conversation/:pseudo`,
        ChatGrpScreen: isDevElectron
          ? ""
          : `${prefix}:communityUri/conversations-de-groupe/:eventId`,
        PdfWelcomeScreen: isDevElectron
          ? ""
          : `${prefix}:communityUri/pdf-bienvenue`,
        SelectActivityScreen: isDevElectron
          ? ""
          : `${prefix}:communityUri/actualites/instants-partage`,
        SelectProposalScreen: isDevElectron
          ? ""
          : `${prefix}:communityUri/actualites/demandes`,
        SelectTemplateScreen: isDevElectron
          ? ""
          : `${prefix}:communityUri/actualites/modeles`,
        SelectTemplateDateScreen: isDevElectron
          ? ""
          : `${prefix}:communityUri/actualites/parametres-publication`,
        PostScreen: isDevElectron
          ? ""
          : `${prefix}:communityUri/actualites/edition-publication`,
        EventInterestedUsersScreen: isDevElectron
          ? ""
          : `${prefix}:communityUri/actualites/publication`,
        EventDetailsScreen: isDevElectron ? "" : `${prefix}detail-publication`,
        AdScreen: isDevElectron
          ? ""
          : `${prefix}${LOCAL_LIFE_ROUTE}/:cityUri/:adUri`,
        TabScreen: {
          screens: {
            LiveTab: {
              screens: {
                LiveScreen: isDevElectron
                  ? ""
                  : `${prefix}:communityUri/actualites`,
                SettingsScreen: `${prefix}:communityUri/parametres`
              }
            },
            ChatTab: {
              screens: {
                ChatOwnScreen: isDevElectron
                  ? ""
                  : `${prefix}${MY_CONVERSATIONS_ROUTE}`,
                ChatGroupScreen: isDevElectron
                  ? ""
                  : `${prefix}:communityUri/conversations-de-groupe`,
                ChatEmployeesScreen: isDevElectron
                  ? ""
                  : `${prefix}:communityUri/a-mon-service`,
                ChatNeighborsScreen: isDevElectron
                  ? ""
                  : `${prefix}:communityUri/mes-voisins`
              }
            },
            AdsTab: {
              screens: {
                AdsScreen: isDevElectron
                  ? ""
                  : `${prefix}:communityUri/vie-locale`,
                AdsMapScreen: isDevElectron
                  ? ""
                  : `${prefix}:communityUri/a-proximite`
              }
            },
            MeteoTab: {
              screens: {
                MeteoScreen: isDevElectron ? "" : `${prefix}:communityUri/meteo`
              }
            },
            CommunityDetailsTab: {
              screens: {
                AppInfosScreen: isDevElectron ? "" : `${prefix}${ABOUT_ROUTE}`,
                CommunityDetailsScreen: isDevElectron
                  ? ""
                  : `${prefix}:communityUri/informations`
              }
            }
          }
        }
      }
    }
  };

  const state = {
    routes: []
  };

  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={() => setIsReady(true)}
      linking={
        Boolean(!user || isFetching)
          ? !accessDenied
            ? loading
            : landing
          : user.needUpdate
          ? registerUser
          : authenticated
      }
      initialState={state}
      onStateChange={state => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = getActiveRouteName(state);

        if (previousRouteName !== currentRouteName) {
          const route = getActiveRoute(state);
          if (route instanceof Object) {
            if (route.path) {
              // alert("delete route.path");
              delete route.path;
            }
            dispatch({
              type: NAVIGATION,
              ...route
            });
          } else {
            dispatch({
              type: NAVIGATION,
              name: route
            });
          }
        }

        // Save the current route name for later comparision
        routeNameRef.current = currentRouteName;
      }}
    >
      {isReady && (
        <ThemeProvider theme={theme}>
          {createRootAppStackNavigator({
            dispatch,
            user,
            accessDenied,
            navigation,
            displayPreview,
            communityDetails,
            isFetching
          })}
          <ErrorManagement
            navigationRef={navigationRef}
            dropDownHolder={dropdownAlertRef.current}
          />
        </ThemeProvider>
      )}
      <DeepLinkManagement />
      <PushNotifications />
      <DropdownAlert
        ref={dropdownAlertRef}
        containerStyle={{ backgroundColor: GREEN_COLOR }}
        tapToCloseEnabled={true}
        closeInterval={6000}
        onTap={onTap}
        onClose={onClose}
        messageNumOfLines={5}
        updateStatusBar={false}
      />
    </NavigationContainer>
  );
}
